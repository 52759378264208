/* Header - Written by Timothy Phillips
 * Standard UWA header - Comes in two varients, origionl and FSEP
 * FSEP is enabled with body.style-version-two. This is intended to eventuially be the default.
 * TODO:
 * - Review and clean up */

@import "_variables";

/* #headercontainer */

// only has a background in the origional rebrand style
body:not(.style-version-two) #headercontainer {
	background-image: url('../img/bgs/header-main-bg-refresh.jpg'); }

#headercontainer {
	background-position: center 20%;
	background-repeat: no-repeat;
	background-color: $colHeader;
	background-size: cover;
	color: white;
	margin-bottom: 0 - $pageGutter;
	text-shadow: 0 0 3px rgba(#000, 0.75);
	&.background-tiled {
		background-size: auto;
		background-repeat: repeat; }
	li:before {
 }		//display: none
	a {
		@include link(0, 1, white);
		i:before {
			display: inline-block;
			text-decoration: none; } }

	> div {
		@include fluidPageContainer; }



	#uwa-header {
		padding-top: 30px;
		padding-bottom: 30px;
		@include mediaLarge {
			padding-top: 20px;
			padding-bottom: 20px; } }

	#header-logo {
		font-size: 0px;
		height: 70px;
		white-space: nowrap;
		@include mediaLarge {
			height: 50px; }
		a {
			box-sizing: content-box;
			height: 70px;
			overflow: visible;
			padding: 0px;
			display: inline-block;
			vertical-align: top;
			font-size: $fontSize;
			text-decoration: none;
			position: relative;
			&:first-child {
				margin-left: -7px; }
			+ a {
				border-left: 3px solid white;
				height: 70px;
				margin: -10px 18px;
				padding: 10px 0 10px 18px;
				box-sizing: content-box;
				@include mediaLarge {
					height: 50px; } } }

		img, svg {
			height: 70px;
			vertical-align: top;
			@include mediaLarge {
				height: 50px; } }

		&.double, &.uwa-logo-descriptor {
			@include mediaSmall {
				height: 35px;
				a + a {
					height: 35px;
					border-left-width: 2px;
					margin: -6px 12px;
					padding: 6px 0 6px 12px; }
				img, svg {
					height: 35px; } } } }


	#globalNavLinks {
		display: none;
		list-style: none;
		position: absolute;
		top: -20px;
		right: 0px;
		font-size: 20px;
		margin: 0px;
		li {
			padding: 0;
			background: none;
			display: inline-block;
			margin: 0px 0px 0px 20px; }
		@include mediaLarge() {
			display: none; } }

	#site-nav {
		margin: 0px;
		font-size: 11px;
		line-height: 11px;
		font-weight: 400;
		text-transform: uppercase;

		list-style: none;
		position: absolute;
		bottom: 0px;
		right: 0px;
		li {
			display: inline-block;
			margin: 0px 0px 0px 20px;
			padding: 0;
			background: none; }
		i {
			font-size: 22px;
			padding: 5px 0px;
			display: block; }
		a {
			width: 40px;
			text-align: center;
			display: block;
			&[class^="icon-info-"]:before {
				font-size: 26px;
				padding: 5px 0px;
				display: inline-block;
				line-height: 22px;
				text-decoration: none;
				width: 100%; } } }


	#site-header {
		padding-top: 20px;
		padding-bottom: 80px; //60px for body overlap
		border-top: 4px solid white;
		outline: none;

		h2 {
			font-size: 16px;
			font-family: $fontUWA;
			line-height: 16px;
			position: relative;
			z-index: 20;
			padding-left: 2px;
			margin: 0px;
			color: white;
			//display: none
			text-align: left;
			a:focus, a:hover {
				text-decoration: underline;
				@include iconBefore($fa-var-home) {
					position: absolute;
					right: 100%;
					top: 2px; } }
			@include mediaLarge() {
				font-size: 14px;
				line-height: 14px; } }
		h1 {
			font-size: 44px;
			font-weight: 300;
			line-height: 40px;
			margin: 0px;
			color: white;
			position: relative;
			z-index: 10;
			font-family: $fontSans;
			text-align: left;
			@include mediaLarge {
				font-size: 30px;
				line-height: 30px; } }


		h2 + h1 {
			font-size: 40px;
			@include mediaLarge {
				font-size: 30px; } } }

	#custom-hero-header {
		//margin-top: -60px
		padding-top: 0;
		padding-bottom: 0; }

	#hero-header {
		display: none;
		border-color: white;
		border-style: solid;
		border-width: 4px 0px 0 0px;
		z-index: $zIndex - 10;
		position: relative;
		img.hero-banner {
			width: 100%; } }

	&.disable-site-header {
		border-bottom: 4px solid white;
		//margin-bottom: 0px
		#site-header {
			display: none; } } }

/* body class overrides */

body.landing-page #headercontainer {
	#site-header h2 {
		display: none; } }

body.header-invert #headercontainer {
	background-image: none;
	background-color: white;
	color: $colBlue;
	text-shadow: 0 0 3px rgba(#fff, 0.75);
	a {
		@include link(0, 1, $colBlue); }
	#site-header {
		border-top: 4px solid $colBlue;
		h1, h2 {
			color: $colBlue; } }
	// #uwa-crest
	// 	.normal
	// 		display: none
	// 	.invert
 }	// 		display: block

/* Logo variations - dark/light/formal/informal */

// The none's have important so they always override leaving the last logo standing to match all remaining rules
#header-logo {

	@include highDpi {
		img.raster {
			display: none !important; } }
	@include lowDpi {
		img.vector {
			display: none !important; } }
	&:not(.informal) {
		img.informal {
			display: none !important; } }
	&.informal {
		img.formal {
			display: none !important; } } }

body:not(.header-invert) #header-logo img.dark {
	display: none !important; }

body.header-invert #header-logo img.light {
	display: none !important; }

body.double-logo {
	#site-header {
		h2 {
			display: none; } }
	#headercontainer #navSearchLink {
		@include mediaSmall {
			display: none; } }

	#subsite-logo svg text {
		font-family: $fontUWA;
		font-size: 18px;
		font-style: italic;
		stroke: none;
		fill: #ffffff;
		text-shadow: none;
		text-shadow: 0 0 3px rgba(0,0,0,0.1); } }

/* body class overrides */

body.header-navigation-hide {
	#headercontainer #site-nav {
		display: none; } }

body.header-minimal {
	#headercontainer {
		margin-bottom: 0px;
		#globalNavLinks, #navSearchLink {
			display: none; }
		#site-header {
			border-top: none;
			padding-top: 0px;
			padding-bottom: 20px; } }
	#centrecontainer > div.contentwrapper:first-child {
		background-image: none; } }

/* .style-version-two variations */

body.header-style-two, body.style-version-two {
	#headercontainer {
		margin-bottom: 0;
		//background-image: none
		#globalNavLinks {
			display: none; }
		#site-header {
			border-top: none;
			padding-top: $size2;
			padding-bottom: $size2;
			background: $colBaseGrey;
			color: $colHeader;
			text-shadow: none;
			@include mediaTiny {
				padding: $size2 $size1; }
			h1 {

				line-height: $lineHeightH2;
				font-family: $fontUWA;
				color: $colHeader;
				text-align: left;
				@include fluidText($fluidH2, $fluidH2Max); }
			h2 {
				display: none; }
			a {
				color: $colHeader !important; } } }


	#centrecontainer > div.contentwrapper:first-child {
		background-image: none; } }


body.header-no-titles {
	#site-header {
		display: none; } }

body.page-wide, body.header-style-two, body.style-version-two {
	#headercontainer {
		> div {
			@include fluidPageContainerWide;
			> * {
				max-width: $pageContentMaxWidthPlus; } } } }


/* styles for before Javascript loaded to reduce page jump */

body.style-version-two {
	> h1, > h2 {
		display: block;
		margin: 0px; }
	> h2 {
		line-height: $lineHeightH2;
		font-family: $fontUWA;
		color: $colHeader;
		text-align: left;
		background: $colBaseGrey;
		padding-top: $size2;
		padding-bottom: $size2;
		@include fluidText($fluidH2, $fluidH2Max);
		@include fluidPageContainer;
		@include mediaMinHuge {
			padding-left: calc((100% - 1200px)/2);
			padding-right: calc((100% - 1200px)/2); } }

	> h1 {
		background-color: $colBlue;
		padding-top: 66px;
		height: 166px;
		padding-bottom: 30px;
		font-size: 0px;
		@include mediaLarge {
			padding-top: 56px;
			padding-bottom: 20px;
			height: 126px; }
		@include mediaSmall {
			padding-top: 20px;
			height: 90px; } } }


/* .uwa-logo-descriptior - The UWA logo plus site title text side by side */

.uwa-logo-descriptor {
	font-size: 0px;
	height: 70px;
	white-space: nowrap;
	@include mediaLarge {
		height: 50px; }
	@include mediaSmall {
		height: 35px;
		a + a {
			height: 35px;
			border-left-width: 2px;
			margin: -6px 12px;
			padding: 6px 0 6px 12px; }
		img, svg {
			height: 35px; } }
	a {
		box-sizing: content-box;
		height: 70px;
		overflow: visible;
		padding: 0px;
		display: inline-block;
		vertical-align: top;
		font-size: $fontSize;
		font-family: $fontUWA;
		text-decoration: none;
		position: relative;
		&:first-child {
			margin-left: -7px; }
		+ a {
			border-left: 3px solid white;
			height: 70px;
			margin: -10px 16px;
			padding: 10px 0 10px 16px;
			box-sizing: content-box;
			@include mediaLarge {
				height: 50px; } } }

	img, svg {
		height: 70px;
		vertical-align: top;
		@include mediaLarge {
			height: 50px; } }
	svg {
		text {
			fill: white;
			font-family: $fontUWA;
			&.italic {
				font-style: italic; }
			&.large {
				font-size: 34px; }
			&.huge {
				font-size: 56px; }
			&.medium {
				font-size: 24px; }
			&.small {
				font-size: 17px; } } }
	&.invert {
		a + a {
			border-left-color: $colBlue; }
		svg text {
			fill: $colBlue; } } }

// Descriptor logos are no longer in use, but we need a title for handbooks
// It also needs more space for letter tails -- the origional was not adequately tested
#headercontainer #header-logo.uwa-logo-descriptor a + a.no-seperator {
	height: 90px;
	border-left: none;
	padding: 10px 0 0 $size2;
	@include mediaLarge {
		height: 80px; }
	@include mediaSmall {
		height: 50px;
		padding: 6px 0 0 $size1; }
	svg {
		height: 80px;
		@include mediaLarge {
			height: 58px; }
		@include mediaSmall {
			height: 40px; } } }

body.header-no-titles #headercontainer #header-logo.uwa-logo-descriptor a + a.no-seperator {
	display: none; }


#acessible-links {
	margin: 0px;
	height: 0px;
	overflow: visible;
	z-index: 999999;
	position: absolute;
	left: 0px;
	right: 0px;
	top: -50px;
	a {
		position: relative;
		background-color: $colBaseGrey;

		padding: 3px 6px;
		border-radius: 0px 0px 5px 5px;
		display: block;
		box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
		color: $colTextLink;
		&:focus {
			top: 50px;
			text-decoration: underline; } } }


// New title tweaks for handbooks

// body.show-site-title #headercontainer #site-header
// 	padding-top: $size1 + $size0
// 	padding-bottom: $size1 + $size0
// 	h2
// 		display: block
