/* Colour pallet - Written by Timothy Phillips */

@import "_variables.sass";


/* Old faculty colours such as .brand-alva */

.brand-alva {
	background-color: $colAlvAlt !important;
	color: $colText !important; }
.brand-business {
	background-color: $colBusAlt !important;
	color: white !important; }
.brand-education {
	background-color: $colEduAlt !important;
	color: white !important; }
.brand-ecm {
	background-color: $colEcmAlt !important;
	color: white !important; }
.brand-indigenous {
	background-color: $colIndAlt !important;
	color: white !important; }
.brand-science {
	background-color: $colSciAlt !important;
	color: white !important; }
.brand-art, .brand-arts {
	background-color: $colArtAlt !important;
	color: white !important; }
.brand-medent, .brand-meddent {
	background-color: $colMedAlt !important;
	color: white !important; }
.brand-law {
	background-color: $colLawAlt !important;
	color: white !important; }

.brand-studyat {
	background-color: $colStudyat !important;
	color: white !important; }

/* font awesome social icon colours such as .fa-twitter and .icon-sauto-color */

.fa-twitter:before {
	color: $colTwitter; }
.fa-facebook:before {
	color: $colFacebook; }
.fa-linkedin:before {
	color: $colLinkedIn; }
.fa-instagram:before {
	color: $colInstagram; }
.fa-flickr:before {
	color: $colFlickr; }
.fa-youtube-play:before {
	color: $colYoutube; }
.fa-google-plus:before {
	color: $colYoutube; }

.fa-snapchat:before {
	color: #C9BE00; }
.fa-weibo:before:before {
	color: #D72928; }
.fa-weixin:before:before {
	color: #78AB34; }


.icons-auto-color, .icons-brand-color {
	*:before {
		color: $colTextGrey !important; }
	.fa-twitter:before {
		color: $colTwitter !important; }
	.fa-facebook:before {
		color: $colFacebook !important; }
	.fa-linkedin:before {
		color: $colLinkedIn !important; }
	.fa-instagram:before {
		color: $colInstagram !important; }
	.fa-flickr:before {
		color: $colFlickr !important; }
	.fa-youtube-play:before {
		color: $colYoutube !important; }
	.fa-google-plus:before {
		color: $colYoutube !important; }

	.fa-snapchat:before {
		color: #C9BE00 !important; }
	.fa-weibo:before:before {
		color: #D72928 !important; }
	.fa-weixin:before:before {
		color: #78AB34 !important; }
	.fa-star:before, .fa-star-o:before {
		color: $colGold !important; }
	.fa-exclamation-triangle:before {
		color: #c25813 !important; } }

/* light and dark text colours */

.text-dark {
	color: black !important;
	p {
		color: black; }
	a {
		@include link(1,0, black); }
	h3 {
		border-bottom-color: rgba($colLinesGrey, 0.5); }
	h1, h2, h3, h4, h5 {
		color: black; }
	&.text-shadow, &.image-block {
		p, h3, h4, a {
			text-shadow: 1px 1px 0px rgba(#fff, 0.6), 0 0 6px rgba(#fff, 0.6); }
		a.button {
			box-shadow: 0px 0px 5px rgba(#fff, 0.4); } } }

.text-light {
	color: white !important;
	p, h1, h2, h3, h4, a, blockquote, blockquote:before, blockquote:after, cite {
		color: white; }
	a {
		@include link(1,0, white);
		&:before {
			color: white; } }
	h3 {
		border-bottom-color: rgba($colLinesGrey, 0.5); }
	&.text-shadow, &.image-block {
		a.button {
			box-shadow: 0px 0px 5px rgba(#000, 0.4); }
		p, h3, h4, a, blockquote, cite {
			text-shadow: 1px 1px 1px rgba(#000, 0.4), 0 0 6px rgba(#000, 0.6); } } }

/* secondary colour palet such as .colour-maroon  or .icon-color-amber*/

.color-maroon {
	background-color: $colMaroon !important; }
.color-crimson {
	background-color: $colCrimson !important; }
.color-tomato {
	background-color: $colTomato !important; }
.color-maria {
	background-color: $colMaria !important; }
.color-autumn {
	background-color: $colAutum !important; }
.color-amber {
	background-color: $colAmber !important; }
.color-seaweed {
	background-color: $colSeaweed !important; }
.color-apple {
	background-color: $colApple !important; }
.color-sushi {
	background-color: $colSushi !important; }
.color-midnight {
	background-color: $colMidnight !important; }
.color-denim {
	background-color: $colDenim !important; }
.color-viking {
	background-color: $colViking !important; }
.color-bossanova {
	background-color: $colBossanova !important; }
.color-orchid {
	background-color: $colOrchid !important; }
.color-plum {
	background-color: $colPlum !important; }



.icon-color-maroon:before {
	color: $colMaroon !important; }
.icon-color-crimson:before {
	color: $colCrimson !important; }
.icon-color-tomato:before {
	color: $colTomato !important; }
.icon-color-maria:before {
	color: $colMaria !important; }
.icon-color-autum:before {
	color: $colAutum !important; }
.icon-color-amber:before {
	color: $colAmber !important; }
.icon-color-seaweed:before {
	color: $colSeaweed !important; }
.icon-color-apple:before {
	color: $colApple !important; }
.icon-color-sushi:before {
	color: $colSushi !important; }
.icon-color-midnight:before {
	color: $colMidnight !important; }
.icon-color-denim:before {
	color: $colDenim !important; }
.icon-color-viking:before {
	color: $colViking !important; }
.icon-color-bossanova:before {
	color: $colBossanova !important; }
.icon-color-orchid:before {
	color: $colOrchid !important; }
.icon-color-plum:before {
	color: $colPlum !important; }

.color-amber,
.color-maroon,
.color-crimson,
.color-maria,
.color-autumn,
.color-seaweed,
.color-apple,
.color-midnight,
.color-denim,
.color-bossanova,
.color-orchid {
	color: white !important;
	&:before,
	&:after {
		color: white !important; } }
.color-amber,
.color-sushi,
.color-tomato,
.color-plum,
.color-viking {
	color: black !important;
	&:before, &:after {
		color: black !important; } }


/* background colours such as .background-sky */


.background-sky {
	background-color: $colBgSky !important;
	border-color: darken($colBgSky, 5%) !important; }
.background-smoke {
	background-color: $colBgSmoke !important;
	border-color: darken($colBgSmoke, 5%) !important;
	// [class^="icon-"]:before
 }	// 	color: $colDenim !important //denim
.background-grey {
	background-color: $colBgGrey !important;
	border-color: darken($colBgGrey, 5%) !important; }
.background-grey-dark {
	background-color: $colBgGreyDark !important;
	border-color: darken($colBgGreyDark, 5%) !important; }


.icon-color-bright {
	&:before, &:after {
		color: $colGold !important; } }

.icon-color-dark {
	&:before, &:after {
		color: $colBlue !important; } }



