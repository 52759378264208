/* Base styles - Written by Timothy Phillips
 * Almost everything derrives from these styles */

@import "_variables";




/* css reset */
// css reset http://meyerweb.com/eric/tools/css/reset/ v2.0 | 20110126 License: none (public domain) */

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	box-sizing: border-box;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline; }

// HTML5 display-role reset for older browsers

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block; }

body {
	line-height: 1; }

ul {
	list-style: none; }

blockquote, q {
	quotes: none; }

blockquote {
	&:before, &:after {
		content: '';
		content: none; } }

q {
	&:before, &:after {
		content: '';
		content: none; } }

table {
	border-collapse: collapse;
	border-spacing: 0; }

/* basic styles for common classless elements */


html {
	// This sets the rem or root em size.
	font-size: #{$sizeFontRoot}px;
 }	// scroll-behavior: smooth

body {
	min-width: 320px;
	margin: 0px;
	padding: 0px;
	color: $colText;
	font-family: $fontSans;
	line-height: $lineHeight;
	font-size: $fluidBody;
	&.style-version-two {
		@include fluidText($fluidBody, $fluidBodyMax); } } // official

a {
	color: $colTextLink;
	text-decoration: underline;
	cursor: pointer;
	&:focus, &:hover {
		text-decoration: none; }
	&:visited {
		color: $colTextLinkVisited; } }


p {
	margin-bottom: $size1;
	margin-top: $size1; }

strong, b {
	font-weight: 600; }

em, i {
	font-style: italic; }

u {
	font-style: underline; }


code {
	padding: 0 3px;
	color: $colText;
	font-family: $fontMono;
	font-size: 12px;
	line-height: 1.25em;
	margin-bottom: 0em;
	border-radius: 5px;
	border: 1px solid darken($colCodeBase, 10%);
	background-color: $colCodeBase;
	text-transform: none; }

pre {
	font-family: $fontMono;
	margin: $size2 0;
	font-size: 12px;
	overflow: auto;
	//color: $colTextGrey
	padding: 0px 6px;
	background-color: $colCodeBase;
	background-image: url("../img/bgs/code-lines.png");
	background-position: top left;
	background-repeat: repeat;
	font-size: 12px;
	line-height: 18px;
	background-attachment: local;
	border-radius: 5px;
	border: 1px solid darken($colCodeBase, 10%);
	> code {
		line-height: 18px;
		padding: 0px 6px;
		display: block;
		//color: $colTextGrey
		font-size: 12px;
		padding: 0px;
		background-color: transparent;
		border-radius: 0px;
		border-width: 0px; }
	&.wrap-lines {
		white-space: pre-wrap; } }


img {
	vertical-align: text-bottom; }

div.content img {
	max-width: 100%; }

sup {
	font-size: 80%;
	vertical-align: super;
	text-transform: lowercase;
 }	//color: $colTextGrey

sub {
	font-size: 80%;
	vertical-align: sub;
 }	//color: $colTextGrey

small {
	font-size: 80%;
	color: $colTextGrey;
	line-height: 1.15em; }

q {
	font-style: italic;
	&:before, &:after {
		display: inline; }
	&:before {
		content: "“"; }
	&:after {
		content: "”"; } }

/* some generic text classes such as .text-center and .font-slab */

.text-center {
	text-align: center !important; }
.text-right {
	text-align: right !important; }
.text-left {
	text-align: left !important; }

.text-reset {
	font-size: $fontSize;
	font-weight: 400;
	color: $colText;
	a {
		@include link(1,0, $colTextLink); } }

.faux-header {
	font-weight: 600;
	font-size: 18px; }

.faux-header-large {
	font-size: 22px;
	line-height: 1.3em; }

.font-slab {
	font-family: $fontUWA !important;
	font-weight: normal; }
.text-grey {
	color: $colTextGrey !important; }
.text-blue {
	color: $colBlue !important; }


.nowrap, .no-wrap {
	white-space: nowrap; }

.transform-none {
	text-transform: none !important;
	* {
		text-transform: none !important; } }

/* a few common display classes such as .display-none */

.max-width {
	width: 100%; }

.display-none {
	display: none !important; }

.display-block {
	display: block; }

/* simple float control such as .clear and .float-right-half */

.no-clear {
	clear: none !important; }

.clear-both {
	clear: both !important;
	&.clearboth { //matrix snippet has content in it. :(
		height: 0;
		overflow: hidden; } }

.clear {
	clear: both !important; }

.clear-right {
	clear: right !important; }

.clear-after:after {
	clear: both;
	content: " ";
	display: block;
	height: 0px;
	width: 100%; }

.float-left {
	float: left !important;
	@include mediaMedium {
		float: none; } }
.float-right {
	float: right !important;
	@include mediaMedium {
		float: none; } }

.float-right-half, .float-right-third, .float-right-quarter {
	@include childNoMargins;
	clear: right;
	float: right;
	margin: 0 0 $size1 $size1;
	// &:not(.feature-box)
 }	// 	padding: 0 0 $size0 $size0


.float-right-half {
	max-width: 50% !important;
	@include mediaMedium {
		float: none;
		max-width: 80% !important;
		margin: 0px auto $size2 auto;
		// &:not(.feature-box)
 }		// 	padding: 0px
	@include mediaSmall {
		max-width: 100% !important;
		margin: 0 0 $size2 0; } }

.float-right-third {
	max-width: 33.33% !important;
	@include mediaMedium {
		max-width: 50% !important;
		padding: 0px; }
	@include mediaSmall {
		float: none;
		max-width: 100% !important;
		margin: 0 0 $size2 0;
		// &:not(.feature-box)
 } }		// 	padding: 0px

.float-right-quarter {
	max-width: 25% !important;
	@include mediaMedium {
		max-width: 33.33% !important; }
	@include mediaSmall {
		max-width: 50% !important; } }



/* Main content headings */

h1, h2, h3, h4, h5, h6 {
	color: $colHeader;
	font-family: $fontSans;
	font-weight: 400;
	margin-bottom: $size1;
	margin-top: $size1 + $size0;
	padding: 0;
	a {
		@include link(0, 1, $colHeader); } }

h1 {
	@include fluidTextOld($fluidH1, $fluidH1Max);
	font-family: $fontUWA;
	line-height: $lineHeightH1;
	margin-bottom: $size3;
	margin-top: $size6;
	text-align: center;
	small {
		display: block;
		font-family: $fontSans;
		font-size: 0.9375rem;
		font-weight: 700;
		letter-spacing: 2px; } }  // official

h2, h1.header2 {
	@include fluidTextOld($fluidH2, $fluidH2Max);
	font-family: $fontUWA;
	line-height: $lineHeightH2;
	margin-bottom: $size3;
	margin-top: $size6;
	text-align: center; }
h3, h1.header3, h2.header3 {
	@include fluidTextOld($fluidH3, $fluidH3Max);
	border-bottom: 1px solid $colLinesGrey;
	clear: both;
	font-family: $fontUWA;
	font-weight: normal;
	line-height: $lineHeightH3;
	margin-top: $size3;
	padding-bottom: $size0;
	padding-top: $size0; }
.text-center h3, h3.no-underline, h3.text-center {
	border: none;
 }	//padding-bottom: 0px


h4 {
	@include fluidTextOld($fluidH4, $fluidH4Max);
	color: $colHeader4;
	line-height: $lineHeightH4;
	margin-top: $size2; }

h5 {
	@include fluidTextOld($fluidH5, $fluidH5Max);
	color: $colHeader4;
	font-weight: 600; // semibold
	line-height: $lineHeightH5;
	margin-top: $size1 + $size0; }


h6 {
	@include fluidTextOld($fluidH6, $fluidH6Max);
	color: $colHeader6;
	font-weight: 700; // bold
	line-height: $lineHeightH6; }

// wider page width has a wider max
body.style-version-two {
	h1 {
		@include fluidText($fluidH1, $fluidH1Max); }
	h2, h1.header2 {
		@include fluidText($fluidH2, $fluidH2Max); }
	h3, h1.header3, h2.header3, .faux-header-3 {
		@include fluidText($fluidH3, $fluidH3Max); }
	h4, .faux-header-4 {
		@include fluidText($fluidH4, $fluidH4Max); }
	h5, .faux-header-5 {
		@include fluidText($fluidH5, $fluidH5Max); }
	h6, .faux-header-6 {
		@include fluidText($fluidH6, $fluidH6Max); } }

// fake overrides for when visual can't match semantic
.faux-header-3 {
	@include fluidTextOld($fluidH3, $fluidH3Max);
	line-height: 1.1em;
	font-weight: 400;
	font-family: $fontUWA; }
.faux-header-4 {
	@include fluidTextOld($fluidH4, $fluidH3Max);
	line-height: 1.1em;
	font-weight: 500; }
.faux-header-5 {
	@include fluidTextOld($fluidH5, $fluidH3Max);
	line-height: 1.1em;
	font-weight: 500; }
.faux-header-6 {
	@include fluidTextOld($fluidH6, $fluidH3Max);
	line-height: 1.1em;
	font-weight: 600; }



/* ToDo: move .epic headers to legacy. */
h3.epic, h4.epic, h5.epic {
	color: #444;
	font-weight: normal;
	font-family: $fontUWA;
	letter-spacing: 1px;
	text-transform: uppercase; }


/* .overview and .introduction text */

p.overview {
	@include fluidText(16, 23); }

p.introduction {
	color: $colIntroduction;
	//font-size: 21px
	font-weight: 300;
	line-height: 1.5;
	margin-bottom: $size1 + $size0;
	@include fluidTextOld($fluidIntroduction, $fluidIntroductionMax); }
.style-version-two p.introduction {
	@include fluidText($fluidIntroduction, $fluidIntroductionMax);

	strong, em {
		font-weight: 400; } }

/* .highlight-box and other simple box styles */

.highlight-box, .critical-alert-box,  .warning-box, .success-box, .info-box {
	min-height: $size2;
	padding: $size2 $size1 $size2 70px;
	border: 1px solid $colLinesGrey;
	background-color: $colBaseGrey;
	margin: $size2 0;
	position: relative;
	@include childNoMargins;
	&:before {
		color: #8b8b8b;
		content: $fc-var-flaticon-info;
		display: block;
		font-family: $fontCustom;
		font-size: 30px;
		left: 0px;
		line-height: 30px;
		margin-top: -15px;
		position: absolute;
		text-align: center;
		top: 50%;
		vertical-align: middle;
		width: 70px; }
	&.no-icon {
		padding: $size2;
		&:before {
			display: none; } } }

div.highlight-box, div.critical-alert-box, div.warning-box, div.success-box, div.info-box {
	margin-bottom: $size2;
	p {
		margin: 0px 0px $size0 0;
		&:last-child {
			margin-bottom: 0px; } }
	h3 {
		border: none;
		padding: 0px; } }

.critical-alert-box {
	color: mix($colAlert, $colText, 20%);
	background-color: $colAlertBase;
	border: 2px solid $colAlert;
	&:before {
		content: $fa-var-exclamation-triangle;
		font-family: $fontFA;
		color: $colAlert; }
	h3, h4, h5 {
		color: mix($colAlert, $colText, 40%); } }

.warning-box {
	color: mix($colWarning, $colText, 20%);
	background-color: $colWarningBase;
	border: 2px solid $colWarning;
	&:before {
		content: $fa-var-exclamation-triangle;
		font-family: $fontFA;
		color: $colWarning; }
	h3, h4, h5 {
		color: mix($colWarning, $colText, 40%); } }

.success-box {
	color:  mix($colSuccess, $colText, 20%);
	background-color: $colSuccessBase;
	border: 2px solid $colSuccess;
	&:before {
		content: $fa-var-check-circle;
		font-family: $fontFA;
		color: $colSuccess; }
	h3, h4, h5 {
		color: mix($colSuccess, $colText, 40%); } }

.info-box {
	color:  mix($colInfo, $colText, 20%);
	background-color: $colInfoBase;
	border: 2px solid $colInfo;
	&:before {
		content: $fa-var-info-circle;
		font-family: $fontFA;
		color: $colInfo; }
	h3, h4, h5 {
		color: mix($colInfo, $colText, 40%); } }


.boxed, .box {
	margin: $size2 0;
	padding: $size2;
	border: 1px solid $colLinesGrey;
	background-color: $colBaseGrey;
	@include childNoMargins; }

.box {
	&.darker {
		background-color: $colBaseGreyDarker;
		border: 1px solid darken($colBaseGreyDarker, 5%); }
	&.light {
		background-color: $colBaseLight;
		border: 1px solid darken($colBaseLight, 5%); }
	&.lighter {
		background-color: $colBaseLighter;
		border: 1px solid darken($colBaseLighter, 5%); } }


div.feature-box {
	//+clearLeft // allow float-right overhang
	@include clear; // the above line caused too many problems, if it's still needed make an exception
	@include childNoMargins;
	clear: both;
	border: 2px solid darken($colBaseGrey, 5%);
	background-color: $colBaseGrey;
	padding: $size2;
	margin: $size2 0;
	&.blue {
		background-color: $colBaseBlue;
		border-width: 0px; }
	@include mediaMedium {
		@include clear; } // pull in float-right overhang
	@include mediaSmall {
		padding: $size1; }
	h3 {
		border-bottom: 0px;
		padding: 0px; }

	.column-half {
		margin-top: 0;
		margin-bottom: 0px;
		@include mediaMedium {
			margin-bottom: $size2;
			&:last-child {
				margin-bottom: 0px; } } }
	.highlight-box {
		background-color: mix($colBaseGrey, white); } }


div[class*="column-"] > div.feature-box:first-child {
	height: 100%; }


div.terms-conditions-box {
	background-color: #FFFFFF;
	height: 300px;
	max-height: 80ch;
	overflow-y: scroll;
	margin: $size1 auto;
	padding: $size1;
	> *:first-child {
		margin-top: 0px; } }


/* div.image-branded adds the UWA curve - CURVE DISCONTINUED */
div.image-branded {
	border-top: 3px solid $colGold;
	position: relative;
	margin-right: auto;
	max-width: 380px; }


/* blockquotes and citation */

blockquote {
	@include childNoMargins;
	@include fluidTextOld($fluidBlockquote, $fluidBlockquoteMax); // Official
	//text-align: center
	color: $colBlockquote; // Official
	display: border-box;
	font-style: italic; // Official
	font-weight: 300; // Official
	line-height: 1.4; // Official
	margin: $size2 auto $size2 auto;
	max-width: $pageContentMaxWidth;
	padding: $size1 $size4;
	position: relative;
	&:before, &:after {
		color: #95a4cf; // official?
		display: block;
		font-family: $fontCustom;
		font-size: 32px;
		font-style: normal;
		height: 32px;
		opacity: 0.5;
		position: absolute;
		text-align: center;
		width: 32px;
		vertical-align: middle;
		line-height: 32px; }
	&:before {
		content: $fc-var-quote-left;
		top: 0px;
		left: 0px; }
	&:after {
		display: block;
		bottom: 0px;
		right: 0px;
		content: $fc-var-quote-right; }
	cite {
		display: block;
		margin-top: $size1; }
	+ p.quote-author, + p.byline,  + cite, + cite + cite {
		max-width: $pageContentMaxWidth;
		margin: -$size1 auto $size2 auto;
		display: block;
		padding: 0px $size4; }
	+ cite + cite {
		margin-top: -$size2;
		margin-bottom: $size2; } }
.info-card blockquote,
.right-col blockquote,
blockquote.compact,
blockquote.small {
	padding: 0px $size3;
	position: relative;
	max-width: $pageContentMaxWidth / 1.5;
	line-height: 1.3;
	&:before, &:after {
		font-size: 28px;
		line-height: 28px;
		height: 28px;
		width: 28px; }
	@include fluidTextOld($fluidBlockquoteSmall, $fluidBlockquoteMaxSmall);
	+ p.quote-author, + p.byline,  + cite, + cite + cite {
		padding-left: $size3;
		max-width: $pageContentMaxWidth / 1.5; } }

blockquote.portrait-right {
	@include mediaMinMedium {
		position: relative;
		padding-right: 120px + $size6;
		&:after {
			right: 120px + $size2; }
		img:first-child {
			position: absolute;
			top: $size1;
			right: 0;
			max-width: 120px; }
		&.compact, &.small {
			padding-right: 120px + $size5;
			img:first-child {
				top: $size0; } } }

	@include mediaMedium {
		img:first-child {
			text-align: center;
			display: block;
			margin: 0px auto $size1 auto;
			height: 120px; }
		&:before {
			top: 120px + $size1; } } }






cite, p.quote-author, p.byline {
	color: $colByline;
	font-size: $fontSizeByline;
	font-weight: 600;
	letter-spacing: -0.25px;
	line-height: $lineHeightByline;
	margin-bottom: $size2;
	small {
		display: block; } }



/* horizontal rules and lines */

hr,
.separator {
	height: 1px;
	overflow: hidden;
	margin: $size2 0;
	border-width: 0 0 1px;
	border-style: solid;
	border-color: #d7d7d7;
	// + h3
 }	// 	margin-top: 1em

.separator {
	border-style: dotted; }

.separator-above {
	margin-top: 1em;
	padding-top: 1em;
	border-top: 1px dotted #d7d7d7; }


