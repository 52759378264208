
/* WARNING: This stylesheet is expected to change and should not be used without first contacting the UWA Website office.
 * University of Western Australia - Core Visual identity stylesheet.
 * Targets HTML5 responsive devices with limited support for IE10.
 * Written by Timothy Phillips, Febuary 2015 though August 2017 and ongoing.
 * Copyright 2015-2017 The University of Western Australia - Digital and Creative Services */



@import "_variables";
@import "_fontcustom-dcs";
@import "_font-files-online";
@import "core/_base";
@import "core/_buttons-and-links";
@import "core/_lists-and-icons";
@import "core/_images";
@import "core/_tables";
@import "core/_layout";
@import "core/_footer";
@import "core/_center";
@import "core/_header";
@import "core/_extras";
@import "core/_matrix";
@import "core/_devices";
@import "core/_colours";
@import "core/_forms";
@import "core/_print";


// This may reduce lag on click in some mobile browsers
a {
	touch-action: manipulation; }

/* A few random classes that probably should be elsewhere */

.dpi-low {
	display: block; }
.dpi-high {
	display: none; }
@include highDpi() {
	.dpi-high {
		display: block; }

	.dpi-low {
		display: none; } }

#legacy-browser {
	display: block;
	width: 100%; }

#uwacore-data {
	display: none; }

/* get rid of back to top links - we don't use them any more */

.content {
	a[href="#top"] {
		display: none; } }

