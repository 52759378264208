/* Buttons and links - Written by Timothy Phillips */


@import "_variables.sass";




/* a.button - Standard call to action button */

a, button, input {
	&.button {
		color: $colGoldText;
		display: inline-block;
		border: 0px solid $colGold;
		background-color: $colGold;
		text-decoration: none !important;
		cursor: pointer;
		vertical-align: middle;
		padding: $size1 + 2;
		font-family: $fontUWA;
		font-size: $fontSizeCTAButton;
		line-height: $lineHeightCTAButton;
		//min-height: 50px
		width: 250px;
		max-width: 100% !important;
		//margin-bottom: $size1
		outline: none !important;
		box-sizing: border-box;
		position: relative;
		text-align: center;
		text-shadow: none !important;
		@include iconAfter($fc-var-core-button-arrows, inherit, 0, $fontCustom) {
			transition: margin $animSpeedHover ease; }
		&:hover, &:focus {
			background-color: lighten($colGold, 10%);
			border-color: lighten($colGold, 10%);
			text-decoration: none;
			outline: none !important;
			&:after {
				margin-left: 3px;
				margin-right: -3px; } }
		&:link,	&:visited {
			text-decoration: none;
			outline: none !important; }
		&:active {
			background-color: darken($colGold, 10%);
			border-color: darken($colGold, 10%);
			outline: none !important; }

		&.gold, &.bright {
			color: $colGoldText !important; }
		&.silver {
			background-color: $colSilver;
			border-color: $colSilver;
			&:focus, &:hover {
				background-color: lighten($colSilver, 3%);
				border-color: lighten($colSilver, 3%); }
			&:active {
				background-color: darken($colSilver, 10%);
				border-color: darken($colSilver, 10%); } }
		&.blue, &.dark {
			background-color: $colBlue;
			border-color: $colBlue;
			color: white !important;
			&:focus, &:hover {
				background-color: lighten($colBlue, 10%);
				border-color: lighten($colBlue, 10%); }
			&:active {
				background-color: darken($colBlue, 10%);
				border-color: darken($colBlue, 10%); } }
		&.no-icon:after {
			display: none; }

		&.border, &.outline {
			border-width: 2px;
			padding: $size1;
			border-color: $colBlue;
			background-color: transparent;
			color: $colBlue !important;
			&:after {
				color: $colBlue; }
			&:focus, &:hover {
				background-color: rgba(#000, 0.08); }
			&:active {
				background-color: rgba(#000, 0.63);
				border-color: rgba(#000, 0.63);
				color: white !important; }
			&.invert {
				border-color: white;
				color: white !important;
				background-color: transparent;
				&:after {
					color: white !important; }
				&:focus, &:hover {
					background-color: rgba(#FFF, 0.25); }
				&:active {
					background-color: rgba(#FFF, 0.58);
					border-color: rgba(#FFF, 0.58);
					color: black !important; } } }
		&.big, &.large {
			padding: $size2 $size2;
			width: 300px; } } }

ul[class^="grid-"], ul[class*="grid-"] {
	a.button {
		width: 100%; } }

/* a.link - faux linklist */

a.link,
p.link a,
p.linklist a {
	display: block;
	margin-bottom: $size1;
	@include iconBefore($fa-var-angle-right, $colGold); }


/* a.cta - call to action link */

a.cta, ul.ctas > li > a {
	padding: 0;
	display: inline-block;
	font-weight: 600;
	margin-left: auto;
	margin-right: auto;
	@include link(0, 1, $colLinkCTA);
	@include iconAfter($fc-var-core-button-arrows, inherit, 0, $fontCustom) {
		transition: margin $animSpeedHover ease;
		font-weight: normal; }
	@include fluidText($fluidCTAText, $fluidCTATextMax);
	&:hover, &:focus {
		&:after {
			margin-left: 3px;
			margin-right: -3px; } } }

ul.ctas > li {
	padding: 0px;
	background-image: none;
	list-style: none; }

.text-center a.link {
	margin-left: 0 - ($size1 + $size0); }


/* ul.actions - what was this for again? */

ul.actions {
	@include clear;
	margin: $size1 (-$size0) ($size2) (-$size0);
	list-style-image: none;

	li {
		padding: $size0;
		margin: 0px;
		float: left;
		a {
			display: block;
			border: 2px solid black;
			padding: $size1 $size0;
			margin: 0px;
			text-align: center;
			line-height: 20px;
			font-size: $fontSize + 1;
			font-family: $fontSans;
			@include iconBefore($fa-var-angle-right, $colGold);
			@include link(0,1, $colBlue); } } }

/* ul.news-gallery - TODO: where was this used again? */
ul.news-gallery a {
	@include link(0, 1, $colLinkCTA);
	p {
		font-family: $fontUWA;
		font-size: 24px;
		margin: 0px; }
	p + p {
		color: $colLinkCTA;
		font-size: 18px;
		font-weight: 600;
		font-family: $fontSans; }
	img {
		margin: 0px; } }



/* ul.stamps - migration for old stamps - not for new use */

ul.stamps a,
a.stamp {
	background: url('../img/bgs/stamp-bg.png') top center no-repeat transparent;
	display: block;
	font-family: $fontSans;
	font-size: $fontSize - 1;
	height: 95px;
	letter-spacing: 0.08em;
	line-height: 1.3em;
	margin: 0px auto $size0 auto;
	padding: $size0 $size1 0 $size1;
	text-align: left;
	text-transform: uppercase;
	width: 200px;
	@include link(0, 1, $colHeader);
	@include iconAfter($fc-var-core-button-arrows, $colGold, 0, $fontCustom) {
		margin-left: $size0;
		white-space: nowrap;
		display: inline;
		margin-left: 3px;
		text-decoration: none !important; } }


ul.stamps, ul.stamp-list {
	margin: $size2 (-$size1) (-$size2) (-$size1);
	font-size: 0px;
	text-align: center;
	list-style: none;
	> li {
		margin: 0px;
		display: inline-block;
		font-size: $fontSize;
		vertical-align: middle;
		background-image: none;
		padding: 0px $size1 $size2 $size1;
		> a {
			margin-bottom: 0px; } } }


// ul.image-links-old
// 	//display: table
// 	//margin-bottom: $size0 + $size2


// 	li
// 		list-style: none
// 		background: none
// 		margin-bottom: 0px
// 		text-align: center
// 		line-height: 0px

// 		a
// 			display: block
// 			position: relative
// 			align-items: center
// 			text-align: center
// 			background-position: center center !important
// 			background-size: cover !important
// 			border-bottom: 4px solid $colGold
// 			font-size: 26px
// 			line-height: 28px
// 			font-family: $fontUWA
// 			text-shadow: 0 0 3px black
// 			overflow: hidden
// 			margin: 0px auto
// 			min-height: 120px
// 			min-width: 160px
// 			//display: table

// 			> img
// 				max-height: 300px
// 				margin: 0px
// 				+mediaSmall
// 					max-height: 200px
// 			&.large
// 				height: 200px
// 				width: 100%
// 			&.hero
// 				height: 300px
// 				width: 100%
// 				transition: background-size $animSlow ease
// 				&.aspect-fit-height
// 					background-size: 100% auto !important
// 					&:hover
// 						background-size: 120% auto !important
// 				&.aspect-fit-width
// 					background-size: auto 100% !important
// 					&:hover
// 						background-size: auto 120% !important
// 			&.small
// 				height: 160px
// 				width: 100%
// 			img.icon
// 				display: block
// 				margin: 0 auto $size2 auto
// 			&:before
// 				position: absolute
// 				display: block
// 				top: 0
// 				content: ""
// 				background: linear-gradient(to top, rgba(#000,0.5) 40%, rgba(#000,0.0) 100%)
// 				width: 100%
// 				height: 100%
// 				z-index: $zIndex
// 			&:hover, &:focus
// 				&:before
// 					background: linear-gradient(to top, rgba(#333,0.5) 40%, rgba(#333,0.0) 100%)
// 			&:active:before
// 				background: linear-gradient(to top, rgba(#000,0.7) 40%, rgba(#000,0.2) 100%)
// 			> span, > p
// 				position: absolute
// 				display: flex
// 				flex-direction: column // allow icons on top
// 				text-align: center
// 				justify-content: center
// 				align-items: center
// 				vertical-align: middle
// 				z-index: $zIndex + 1
// 				width: 100%
// 				padding: $size1
// 				top: 0
// 				left: 0
// 				right: 0
// 				bottom: 0
// 				margin: 0px
// 				span
// 					display: block

// 			+link(0, 1, white)
// 	&.no-border li a
// 		border-width: 0px

// #legacy-browser ul.image-links li a
// 	span, p
// 		padding-top: 25%
// 	&:before
// 		background: url('https://static.weboffice.uwa.edu.au/visualid/core-rebrand/img/bgs/bg-black-trans-light.png') top left repeat transparent
// 	&:hover, &:focus
// 		&:before
// 			background: url('https://static.weboffice.uwa.edu.au/visualid/core-rebrand/img/bgs/bg-black-trans-heavy.png') top left repeat transparent



/* ul.icon-buttons - coloured boxes with icon and links */

ul.icon-buttons {
	list-style-image: none;

	li {
		background-image: none;
		a {
			color: white;
			background-color: $colBlue;
			display: block;
			text-align: center;
			padding: ($size0 + 32px + $size0) $size1 $size0 $size1;
			height: 100px;
			white-space: nowrap;
			position: relative;
			text-decoration: none;
			overflow: hidden;
			@include mediaSmall {
				padding: $size0 $size0 $size0 ($size1 + 32px + $size1);
				height: 60px;
				text-align: left; }

			span.icon {
				position: absolute;
				width: 100%;
				height: 32px;
				line-height: 32px;
				top: $size1 * 1.5;
				left: 0;
				//margin-left: -16px !important
				text-align: center;
				img {
					max-height: 100%;
					max-width: 100%;
					vertical-align: bottom; }
				@include mediaSmall {

					left: $size1;
					top: 50%;
					margin-top: -16px;
					line-height: 32px;
					height: 32px;
					width: 32px;
					img {
						vertical-align: middle; } } }

			&:before {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				text-align: center;
				font-size: 32px;
				line-height: 32px;
				padding-bottom: $size1;
				padding-top: $size1 * 1.5;
				font-family: $fontFA;
				z-index: $zIndex;
				@include mediaSmall {
					line-height: 60px;
					text-align: left;
					padding: 0 0 0 $size1; } }

			&[class^="fc-"], &[class*="fc-"] {
				&:before {
					font-family: $fontCustom;
					text-decoration: none; } }


			&:after {
				display: inline-block;
				vertical-align: middle;
				content: "";
				height: 100%; }
			p {
				font-family: $fontUWA;
				font-size: 19px;
				line-height: 22px;
				display: inline-block;
				vertical-align: middle;
				width: 100%;
				white-space: normal;
				margin: 0px;
				//position: relative
				//z-index: $zIndex + 1
				&:before {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: $zIndex;
					content: ""; } }

			&:focus, &:hover {
				p:before {
					background-color: rgba(#fff, 0.2); } }
			&:active p:before {
				background-color: rgba(#000, 0.2); }

			&.fa-facebook {
				background-color: $colFacebook; }
			&.fa-instagram {
				background-color: $colInstagram; }
			&.fa-youtube {
				background-color: $colYoutube; }
			&.fa-linkedin {
				background-color: $colLinkedIn; }
			&.fa-twitter {
				background-color: $colTwitter; }
			&.gold, &.bright {
				background-color: $colGold;
				//&:focus, &:hover
 }				///background-color: lighten($colGold, 10%)
			&.grey {
				background-color: $colBaseGreyDarker;
				//&:focus, &:hover
 }				///background-color: lighten($colBaseGreyDarker, 10%)
			&.orange {
				background-color: #AA4F20;
				//&:focus, &:hover
 }				///background-color: lighten(#AA4F20, 10%)
			&.gold, &.grey, &.bright {
				@include link(0,1, $colHeader); } } }

	@include mediaMinSmall {
		&.medium {
			li a {
				padding: ($size2 + 48px ) $size2 $size1 $size2;
				height: 125px;
				span.icon {
					height: 48px;
					line-height: 48px;
					top: $size2;
					left: 0; }
				&:before {
					top: 0;
					padding-top: $size2 + $size0;
					font-size: 40px;
					line-height: 40px; } } }

		&.large {
			li a {
				padding: ($size2 + 64px ) $size2 $size1 $size2;
				height: 150px;
				span.icon {
					height: 64px;
					line-height: 64px;
					top: $size2;
					left: 0; }
				&:before {
					top: 0;
					padding-top: $size2 + $size0;
					font-size: 48px;
					line-height: 48px; } } }

		&.huge {
			li a {
				padding: ($size2 + 64px ) $size2 $size1 $size2;
				height: 200px;
				span.icon {
					height: 64px;
					line-height: 64px;
					top: $size3;
					left: 0; }
				&:before {
					top: 0;
					padding-top: $size3;
					font-size: 48px;
					line-height: 64px; } } } }



	// &.medium
	// 	li a
	// 		padding: ($size0 + 32px + $size1) $size1 $size0 $size1
	// 		height: 125px
	// 		+mediaSmall
	// 			padding: $size0 $size0 $size0 ($size1 + 32px + $size1)
	// 			height: 60px
	// &.large
	// 	li a
	// 		padding: ($size2 + 48px ) $size2 $size1 $size2
	// 		height: 150px
	// 		+mediaSmall
	// 			padding: $size0 $size0 $size0 ($size1 + 32px + $size1)
	// 			height: 60px
	// 		&:before
	// 			top: 0
	// 			padding-top: $size2 + $size0
	// 			font-size: 48px
	// 			line-height: 48px
	// 			+mediaSmall
	// 				line-height: 60px
	// 				height: 60px
	// 				font-size: 32px
	// 				line-height: 60px
	// 				text-align: left
	// 				padding: 0 0 0 $size1
	// 		img.icon
	// 			display: block
	// 			vertical-align: middle
	// 			top: $size2
	// 			max-height: 48px
	// 			max-width: 48px
	// 			line-height: 48px
	// 			margin-left: -24px !important
	// 			+mediaSmall
	// 				max-height: 32px
	// 				max-width: 32px
	// 				line-height: 32px
	// 				margin-left: 0px !important
	// 				left: $size1
	// 				top: 50%
	// 				margin-top: -16px
	// 				line-height: 60px


	// &.huge
	// 	li a
	// 		padding: ($size2 + $size1 + 48px ) $size2 $size1 $size2
	// 		height: 150px
	// 		+mediaSmall
	// 			padding: $size0 $size0 $size0 ($size1 + 32px + $size1)
	// 			height: 60px
	// 		&:before
	// 			top: 0
	// 			padding-top: $size2 + $size0
	// 			font-size: 48px
	// 			line-height: 48px
	// 			+mediaSmall
	// 				line-height: 60px
	// 				height: 60px
	// 				font-size: 32px
	// 				line-height: 60px
	// 				text-align: left
	// 				padding: 0 0 0 $size1
	// 		img.icon
	// 			display: block
	// 			vertical-align: middle
	// 			top: $size1 + $size0
	// 			max-height: 64px
	// 			max-width: 64px
	// 			line-height: 64px
	// 			margin-left: -32px !important
	// 			+mediaSmall
	// 				max-height: 32px
	// 				max-width: 32px
	// 				line-height: 32px
	// 				margin-left: 0px !important
	// 				left: $size1
	// 				top: 50%
	// 				margin-top: -16px
 }	// 				line-height: 60px



/* ul.contact-combo-links - blue circle over grey to white gradient button - supersceded by info snippets */

ul.contact-combo-links {
	li {
		position: relative;
		//padding: $size1
		text-align: center;
		min-height: 200px;
		&:before {
			background: linear-gradient(to bottom, rgba($colBaseGrey, 1) , rgba($colBaseGrey, 0) 125px);
			bottom: $size0;
			content: "";
			display: block;
			left: $size1;
			position: absolute;
			right: $size1;
			top: $size4 + $size1; }
		> * {
			position: relative; }
		> a:first-child {
			display: block;
			font-size: $fontSize + 4;
			font-family: $fontUWA;
			margin: 0px auto $size1 auto;
			padding-top: $size8 + $size1;
			padding-left: $size1;
			padding-right: $size1;
			img.icon, &:before, &:after {
				box-sizing: border-box;
				display: block;
				height: $size8;
				left: 50%;
				margin-left: -$size4;
				position: absolute;
				top: 0;
				width: $size8; }
			img.icon, &:before {
				color: white;
				font-family: $fontFA;
				font-size: 32px;
				line-height: $size4;
				padding: $size2;
				vertical-align: middle;
				z-index: $zIndex + 1; }
			&:after {
				background-color: $colBlue;
				border-radius: 50%;
				content: "";
				z-index: $zIndex; }
			&:hover, &:focus {
				&:after {
					box-shadow: 0px 1px 2px rgba(0,0,0,0.25); }
				img.icon, &:after, &:before {
					top: -2px; } }
			&:before {
				text-decoration: underline; }
			&:before {
				text-decoration: none; } } } }

/* ul.icon-link-blocks - Grey box with icon and link with lots of text - like the old bevel button */

ul.icon-link-blocks li a,
a.icon-link-block {
	@include link(0, 1, $colTextLink);
	background-color: $colBaseGrey;
	border: 1px solid darken($colBaseGrey, 10%);
	display: block;
	padding: ($size1 - 1) $size3 $size0 ($size2 + 72);
	position: relative;
	box-sizing: border-box;
	min-height: 60px + $size2 + 2;
	font-family: $fontUWA;
	font-size: 20px;
	line-height: 1.05;
	margin-bottom: $size1 !important;
	&:after {
		content: $fc-var-core-button-arrows;
		font-family: $fontCustom;
		position: absolute;
		display: block;
		top: 50%;
		right: $size1;
		color: $colTextGrey;
		opacity: 0.5;
		line-height: $size2;
		height: $size2;
		margin-top: -$size1;
		transition: margin $animSpeedHover ease; }
	&:hover, &:focus {
		&:after {
			margin-left: 3px;
			margin-right: -3px; } }
	img:first-child {
		position: absolute;
		top: $size1;
		left: $size1;
		max-width: 72px;
		max-height: 60px;
		clip: rect(0 72px 60px 0); }
	span.icon {
		position: absolute;
		top: $size1;
		left: $size1;
		width: 72px;
		height: 60px;
		background-position: center 20%;
		background-size: cover; } }
ul.icon-link-blocks li {
	background: none;
	padding: 0px; }

.furtherinfo,
.right-col,
.info-card {
	a.icon-link-block {
		background-color: $colBaseGreyDarker;
		border-color: darken($colBaseGreyDarker, 10%); } }
