/* All font files with absolute paths - used by uwacore.sass */

@font-face {
  font-family: 'Source Sans Pro';
  src: url('https://static.weboffice.uwa.edu.au/visualid/fonts/source-sans-pro/SourceSansPro-Regular.ttf');
  font-weight: 400;
  font-display: swap; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url('https://static.weboffice.uwa.edu.au/visualid/fonts/source-sans-pro/SourceSansPro-Light.ttf');
  font-weight: 300;
  font-display: swap; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url('https://static.weboffice.uwa.edu.au/visualid/fonts/source-sans-pro/SourceSansPro-Semibold.ttf');
  font-weight: 600;
  font-display: swap; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url('https://static.weboffice.uwa.edu.au/visualid/fonts/source-sans-pro/SourceSansPro-Bold.ttf');
  font-weight: 700;
  font-display: swap;
  font-weight: bold; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url('https://static.weboffice.uwa.edu.au/visualid/fonts/source-sans-pro/SourceSansPro-Italic.ttf');
  font-style: italic;
  font-weight: 400;
  font-display: swap; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url('https://static.weboffice.uwa.edu.au/visualid/fonts/source-sans-pro/SourceSansPro-LightItalic.ttf');
  font-style: italic;
  font-weight: 300;
  font-display: swap; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url('https://static.weboffice.uwa.edu.au/visualid/fonts/source-sans-pro/SourceSansPro-SemiboldItalic.ttf');
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'UWA Regular';
  src: url('https://static.weboffice.uwa.edu.au/visualid/fonts/uwa/uwa-slab-regular.eot'); // IE9 Compat Modes
  src: url('https://static.weboffice.uwa.edu.au/visualid/fonts/uwa/uwa-slab-regular.eot?#iefix') format("embedded-opentype"), url('https://static.weboffice.uwa.edu.au/visualid/fonts/uwa/uwa-slab-regular.otf') format("opentype"), url('https://static.weboffice.uwa.edu.au/visualid/fonts/uwa/uwa-slab-regular.woff') format('woff');
  font-weight: 400;
  font-display: swap; }

@font-face {
  font-family: 'UWA Regular';
  src: url('https://static.weboffice.uwa.edu.au/visualid/fonts/uwa/uwa-slab-italic.eot'); // IE9 Compat Modes
  src: url('https://static.weboffice.uwa.edu.au/visualid/fonts/uwa/uwa-slab-italic.eot?#iefix') format("embedded-opentype"), url('https://static.weboffice.uwa.edu.au/visualid/fonts/uwa/uwa-slab-italic.otf') format("opentype"), url('https://static.weboffice.uwa.edu.au/visualid/fonts/uwa/uwa-slab-italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "fontcustom-dcs";
  src: url("https://static.weboffice.uwa.edu.au/visualid/core-rebrand/fonts/fontcustom/fontcustom-dcs.eot?r=7");
  src: url("https://static.weboffice.uwa.edu.au/visualid/core-rebrand/fonts/fontcustom/fontcustom-dcs.eot?r=7#iefix") format("embedded-opentype"), url("https://static.weboffice.uwa.edu.au/visualid/core-rebrand/fonts/fontcustom/fontcustom-dcs.woff?r=7") format("woff"), url("https://static.weboffice.uwa.edu.au/visualid/core-rebrand/fonts/fontcustom/fontcustom-dcs.ttf?r=7") format("truetype"), url("https://static.weboffice.uwa.edu.au/visualid/core-rebrand/fonts/fontcustom/fontcustom-dcs.svg?r=7#fontcustom-dcs") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }


@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "fontcustom-dcs";
    src: url("https://static.weboffice.uwa.edu.au/visualid/core-rebrand/fonts/fontcustom/fontcustom-dcs.svg?r=7#fontcustom-dcs") format("svg");
    font-display: block; } }


