/* Extras - Written by Timothy Phillips
 * Miscelanious stuff extra devices and plugin stuff
 * TODO:
 * - clean up or move elsewhere? */

@import "_variables";

/* matrix-tweaks */

img.sq-icon {
	vertical-align: middle; }

/* ul.events-list - used with matrix event feeds */

ul.event-list {
	&:not([class*=" grid-"]) > li {
		margin-bottom: $size1; }
	.event-item {
		padding-left: $sizeIconMedium + $size1;
		position: relative;

		.date {
			font-weight: 600; }
		.subname {
			display: none; }
		.category {
			color: $colTextGrey; }
		.icon-date {
			position: absolute;
			top: 0;
			left: 0;
			width: $sizeIconMedium;
			height: $sizeIconMedium;
			text-transform: uppercase;
			text-align: center;
			color: $colHeader;
			font-size: 24px;
			font-weight: 600;
			background-color: white;
			line-height: 1em;
			padding-top: $size0;
			small {
				display: block;
				color: $colHeader;
				font-size: 12px;
				line-height: 1em; } } } }



/* extra font awesome compatability fixes */

div.content {
	ul.fa-ul {
		list-style-image: none;
		&.inline-list {
			margin-left: 0px; }
		li {
			display: inline-block;
			margin-bottom: 0.8em;
			margin-left: 3em;
			margin-top: 0.8em;
			padding-left: 0px;
			padding-right: 1em;
			background-image: none; } }
	i.fa {
		&.colour {
			color: #49a0b5; }
		&.colour-light {
			color: #a8d1db; }
		&.colour-dark {
			color: #234d56; } } }



/* div.overflow-wrapper - scroll buttons for mobiles - TODO: Is this used? */
div.overflow-wrapper {
	position: relative;
	> .overflow-box {
		overflow: auto; }
	span {
		&[class^="overflow-"] {
			display: none;
			position: absolute;
			text-align: center;
			color: white;

			background: rgba(220, 220, 220, 0.7);
			border-radius: 10px;
			cursor: pointer;
			width: 40px;
			height: 40px;
			background-repeat: no-repeat;
			background-position: center; }
		&.overflow-up {
			background-image: url("https://static.weboffice.uwa.edu.au/visualid/graphics/id-css-devices/nav-arrow-circle-up.png");
			top: 0px;
			left: 50%;
			margin-left: -20px;
			margin-top: 3px; }
		&.overflow-down {
			background-image: url("https://static.weboffice.uwa.edu.au/visualid/graphics/id-css-devices/nav-arrow-circle-down.png");
			bottom: 0px;
			left: 50%;
			margin-left: -20px;
			margin-bottom: 3px; }
		&.overflow-left {
			background-image: url("https://static.weboffice.uwa.edu.au/visualid/graphics/id-css-devices/nav-arrow-circle-left.png");
			left: 0px;
			top: 50%;
			margin-top: -20px;
			margin-left: 3px; }
		&.overflow-right {
			background-image: url("https://static.weboffice.uwa.edu.au/visualid/graphics/id-css-devices/nav-arrow-circle-right.png");
			right: 0px;
			top: 50%;
			margin-top: -20px;
			margin-right: 3px; } } }


/* dl.device-paramaters - used to specify paramaters for some devices in WYSIWYG format */
@keyframes dp_spin {
	from {
		transform: rotate(0deg); }
	to {
		transform: rotate(360deg); } }

@keyframes dp_spinflip {
	from {
		transform: rotate(360deg) scaleX(-1);
		filter: FlipH; }
	to {
		transform: rotate(0deg) scaleX(-1);
		filter: FlipH; } }




dl.device-paramaters {
	text-align: center;
	padding: $size4;
	&:before {
		display: inline-block;
		content: $fa-var-spinner;
		font-family: $fontFA;
		text-align: center;
		color: $colTextGrey;
		margin: 0 auto;
		font-size: $size2;
		// width: 25px
		// line-height: 25px
		// font-size: 16px
		vertical-align: bottom;
		animation: dp_spinflip 2s infinite steps(8); }
	&.loading:before {
		//display: none
		animation: dp_spin 2s infinite steps(8); }
	> * {
		display: none; } }

div.ajax-loading {
	position: relative;
	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		width: $size2;
		height: $size2;
		margin-left: -$size1;
		margin-top: -$size1;
		line-height: $size2;
		display: block;
		content: $fa-var-spinner;
		text-shadow: 0 0 6px white;
		font-size: $size2;
		font-family: $fontFA;
		text-align: center;
		color: $colTextGrey;
		vertical-align: bottom;
		animation: dp_spin 2s infinite steps(8); }
	> * {
		opacity: 0.5; } }


/* uwacore.scrollTo uses this to emphasise the focus area */

.scroll-target {
	position: relative;
	z-index: $zIndex; }
#scroll-target-indicator {
	z-index: $zIndex + 100;
	position: absolute;
	left: $size3;
	right: $size3;
	display: none;
	pointer-events: none;
	//outline: 1px dotted red
	&:before, &:after {
		position: absolute;
		display: block;
		content: "";
		top: 0px;
		height: 100%;
		background-color: $colBlue;
		width: $size1;
		opacity: 0.3; }
	&:before {
		margin-right: $size1;
		right: 100%;
		border-radius: $size1 3px 3px $size1; }
	&:after {
		margin-left: $size1;
		left: 100%;
		border-radius: 3px $size1 $size1 3px; } }


/* TODO: is this used?  */


span.big-blue-number {
	display: inline-block;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background-color: $colBlue;
	color: white;
	font-family: $fontUWA;
	text-align: center;
	font-size: 60px;
	text-align: center;
	line-height: 95px;
	vertical-align: middle; }


/* Block with image, title and blurb from FSEP - used on Sport */

div.hero-card, div.event-block {
	background: $colBaseGrey;
	padding: $size1;
	position: relative;
	padding-bottom: $size3 + 50;
	color: $colTextGrey;
	div {
		margin: (-$size1) (-$size1) 0 (-$size1);
		background-position: center 20%;
		-webkit-background-size: cover;
		background-size: cover;
		img {
			width: 100%; } }
	ul.icons > li {
		font-weight: 600;
		&:before {
			color: $colTextGrey;
			font-weight: normal; } }
	h3 {
		margin: $size1 0;
		padding: 0px;
		font-size: $fluidH3;
		border: 0px;
		.type, small {
			margin: (-$size1) (-$size1) 0px (-$size1);
			background-color: $colBaseLight;
			font-family: $fontSans;
			letter-spacing: 2px;
			text-transform: uppercase;
			font-size: $fontSize;
			padding: $size0 $size1;
			font-weight: 600;
			line-height: 1;
			margin-bottom: $size0; } }
	a.button {
		min-width: 150px;
		width: 200px;
		max-width: 100%; }
	p.absolute-bottom {
		position: absolute;
		bottom: $size1;
		left: 0px;
		width: 100%; } }




/* .card-block grey box with left border from FSEP */
.card-block {
	background-color: $colBaseGrey;
	border-left: 3px solid $colBlue;
	padding: $size2;
	font-size: 0.9em;
	//max-width: 400px
	@include childNoMargins;
	&.border-gold {
		border-left-color: $colGold; }
	h3 {
		padding: 0px;
		border: none;
		font-size: $fluidH3;
		line-height: 1.1;
		small {
			font-size: $fontSize;
			display: block;
			text-transform: uppercase;
			font-family: $fontSans;
			font-weight: 600;
			color: $colHeader;
			opacity: 0.8; } }
	h3:not(:first-child),
	.new-section {
		margin-top: $size2;
		&:before {
			content: "";
			display: block;

			height: $size2;
			margin: 0 (-$size2) $size2 (-$size2 - 3);
			background-color: white; } }
	a.button {
		display: block; }

	h4 {
		font-size: $fluidH4; }
	h5 {
		font-size: $fluidH5; }
	h6 {
		font-size: $fluidH5; }
	dl {
		dt {
			margin: 0px; }
		dd {
			margin-bottom: $size0; } }
	.highlight-box {
		padding: $size0;
		background-color: $colBaseLight;
		&:before {
			display: none; } } }



/* matrix simple edit mode tweaks */

#sq_backend_sections {
	table.bodytext {
		> tbody > tr > td {
			padding: 0px;
			> table {
				margin: 0px;
				> tbody > tr > td {
					padding: 0px; } } } }

	table[layout_type="div"] {
		border: 1px solid #bbbbbb;
		position: relative;
		&:before {
			color: $colTextGrey;
			content: attr(layout_type) " #" attr(id) " (" attr(css_class) ")";
			font-family: $fontMono;
			margin: 2px $size1;
			width: 100%;
			position: absolute;
			text-align: center; } }

	#htmlarea table {
		margin: 0px; }
	table.sq-backend-table {
		background-color: #eeeeee; } }



