/* Micro devices - Written by Timothy Phillips
 * TODO:
 * - Cleanup and move elsewhere */

@import "_variables.sass";


/* touch swipe overlay hints - Todo: is this still even used? */

div#uwacore-touchhints {
	position: absolute;
	width: 300px;
	height: 300px;
	z-index: $zIndexTouchHints;
	margin-left: -150px;
	margin-top: -150px;
	display: none;
	i {
		display: block;
		position: absolute;
		width: $size3;
		height: $size3;
		line-height: $size3;
		text-align: center;
		vertical-align: center;
		opacity: 1;
		&:before {
			font-size: $size3;
			color: white;
			text-shadow: 0 0 5px black; }
		&.hint-center {
			top: 50%;
			left: 50%;
			margin-top: -$size2;
			margin-left: -$size2;
			&:before {
				font-size: $size0; } }

		&.hint-left {
			top: 50%;
			left: 0;
			margin-top: -$size2;
			//&:before
 }			//	content: $fa-var-angle-double-left
		&.hint-right {
			top: 50%;
			right: 0;
			margin-top: -$size2;
			//&:before
 }			//	content: $fa-var-angle-double-right
		&.hint-up {
			top: 0;
			left: 50%;
			margin-left: -$size2;
			//&:before
 }			//	content: $fa-var-angle-double-up
		&.hint-down {
			bottom: 0;
			left: 50%;
			margin-left: -$size2;
			//&:before
 } } }			//	content: $fa-var-angle-double-down

/* big numbered steps ToDo: move to legacy section or support with automatic version */

h3[class^="step-"] {
	border-bottom: 0px; }

span[class^="step-"] {
	margin-top: $sizePadding;
	display: block;
	font-family: $fontUWA;
	font-size: 20px;
	font-weight: normal;
	padding: 20px 0 20px 90px;
	position: relative;
	vertical-align: middle;
	min-height: 48px;
	&:before {
		border-radius: 35px;
		border: 1px solid black;
		content: "00";
		display: block;
		font-family: $fontUWA;
		font-size: 36px;
		font-weight: normal;
		height: 70px;
		left: 0px;
		line-height: 70px;
		margin-top: -35px;
		position: absolute;
		text-align: center;
		top: 50%;
		vertical-align: middle;
		width: 70px; } }

@for $i from 0 through 9 {
	span.step-0#{$i} {
		&:before {
			content: "#{$i}"; } } }

@for $i from 10 through 20 {
	span.step-#{$i} {
		&:before {
			content: "#{$i}"; } } }


/* new automatic .step class - not an offical style */

div.content {
	counter-reset: stepHeaderThree;
	counter-reset: stepHeaderFour; }

h3, h4 {
	&.step {
		position: relative;
		&:before {
			top: 50%;
			border-radius: 50%;
			border: 1px solid mix($colTextGrey, $colText);
			display: inline-block;
			font-family: $fontUWA;
			font-weight: normal;
			text-align: center;
			vertical-align: middle;
			position: absolute;
			color: mix($colTextGrey, $colText); } } }
h3.step {
	margin-left: $size6;
	margin-bottom: $size2;
	counter-increment: stepHeaderThree;
	&.reset {
		counter-reset: stepHeaderThree; }
	&:before {
		content: counter(stepHeaderThree);
		height: $size4;
		width: $size4;
		margin-left: -$size6;
		margin-top: 0 - $size4 / 2;
		line-height: $size4 * 0.95; } }

h4.step {
	margin-left: $size5;
	margin-bottom: $size2;
	counter-increment: stepHeaderFour;
	&.reset {
		counter-reset: stepHeaderFour; }
	&:before {
		content: counter(stepHeaderFour);
		height: $size3;
		width: $size3;
		margin-top: 0 - $size3 / 2;
		line-height: $size3 * 0.95;
		margin-left: -$size5; } }


ol.steps {
	counter-reset: stepList;
	padding: 0px;
	margin: $size2 0;
	> li {
		position: relative;
		counter-increment: stepList;
		list-style: none;
		padding-left: $size6;
		&:before {
			content: counter(stepList);
			top: $size0;
			border-radius: 50%;
			height: $size3 + $size0;
			width: $size3 + $size0;
			margin-left: -$size6;
			line-height: ($size3 + $size0) * 0.9;
			border: 1px solid $colHeader;
			display: inline-block;
			font-family: $fontUWA;
			font-weight: normal;
			text-align: center;
			vertical-align: middle;
			position: absolute;
			font-size: 26px;
			color: $colHeader; }
		+ li {
			margin-top: $size2; } } }


/* div.background-video */

div.background-video {
	@include clear;
	position: relative;
	overflow: hidden;
	width: 100%;
	min-height: 200px;
	margin-bottom: $size1;
	video {
		position: absolute;
		right: 0px;
		bottom: 0px;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		z-index: $zIndex + 1;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);// Saf3.1+, Chrome
		-moz-transform: translate(-50%, -50%); // FF3.5+
		-ms-transform: translate(-50%, -50%); // IE9
		-o-transform: translate(-50%, -50%); // Opera 10.5+
		transform: translate(-50%, -50%); }
	div {
		z-index: $zIndex + 3;
		position: relative; }
	[class^="video-overlay-"] {
		position: absolute;
		right: 0px;
		bottom: 0px;
		min-width: 100%;
		min-height: 100%;
		z-index: $zIndex + 2; }
	.video-overlay-dark {
		background-color: rgba(#000,0.3); }
	.video-overlay-light {
		background-color: rgba(#FFF,0.3); } }





/* Wrap table in div.overflow-scroll for rudimentary handle mobile */

div.overflow-scroll {
	width: 100%;
	overflow: auto; }

/* ul.statistics - TODO: what's this used for? */

ul.statistics {
	font-size: 0px;
	text-align: center;
	li {
		display: inline-block;
		padding: 40px 0;
		margin: $size1;
		width: 180px;
		height: 180px;
		color: $colText;
		border: 3px solid $colText;
		border-radius: 50%;
		text-align: center;
		vertical-align: top;
		p {
			margin: 0px;
			line-height: 1.2; }
		p:first-child {
			font-family: $fontUWA;
			font-size: 40px;
			margin-bottom: $size0; }
		p:not(:first-child) {
			text-transform: uppercase;
			letter-spacing: 2px;
			font-size: 16px;
			font-weight: 600;
			padding: 0px $size1; }
		p:nth-child(3) {
			padding: 0px $size1 + $size0; }
		p:nth-child(4) {
			padding: 0px $size2; }
		p:nth-child(5) {
			padding: 0px $size4; } } }


