/* Print styles - Written by Timothy Phillips */

@media print {
	body, #headercontainer, #centrecontainer, #footercontainer {
		min-width: 800px;
		max-width: 1000px; }
	body.style-version-two {
		max-width: 1200px;
		#headercontainer, #centrecontainer, #footercontainer {
			max-width: 1200px; } }
	div.inner {
		width: 100%;

		margin: 0px auto !important; }
	#headercontainer {

		background: none !important;
		border-bottom: 1px solid $colLinesGrey;
		min-height: 90px;

		#globalNavLinks, #site-nav {
			display: none; }
		#uwa-header {
			padding: 0; }

		#header-logo {
			float: right;
			height: 50px;
			#uwa-crest {
				img {
					display: none !important; }
				img.dark.formal.vector {
					display: block !important;
					height: 70px; } } }
		#site-header {
			padding: 0px 0px $size1 0px !important;
			background: none !important;
			> div.inner {
				padding: 0 240px 0 0;
				box-sizing: border-box;
				h2 {
					padding-bottom: $size1;
					display: block;
					font-size: $fontSize + 2; }
				h1, h2, a {
					color: black;
					text-shadow: none; } } } }
	#centrecontainer > div.contentwrapper {
		padding: $size1 0 $size2 0 !important;
		background: none !important; }
	#uwacore-breadcrumbs {
		display: none; }

	#footercontainer, #overlay-header {
		display: none; }

	#centrecontainer ul.image-links li a {
		border: 2px solid $colGold;
		&:before {
			display: none; }
		div.copy, div.overlay {
			background: white;
			color: black;
			text-shadow: none;
			display: block;
			position: relative;
			height: auto;
			padding: $size1; }
		div.overlay {
			padding: 0px $size1; } }

	.highlight-box, .box {
		// show background, but lighter to ensure it prints fine
		background-color: $colBgSmoke;
		color-adjust: exact;
		-webkit-print-color-adjust: exact;
		print-color-adjust: exact; }

	#uwa-logo-descriptor {
		// Descriptor logos don't fit so well, there's a fallback title that will do
		display: none !important; }

	.print-hide {
		display: none !important; } }
