//
// Icon Font: fontcustom-dcs
//


$fc-var-angle-down: "\f100";
$fc-var-angle-left: "\f101";
$fc-var-angle-right: "\f102";
$fc-var-angle-up: "\f103";
$fc-var-arrow-right: "\f104";
$fc-var-close-cross: "\f105";
$fc-var-core-bullet: "\f106";
$fc-var-core-bullet-link: "\f107";
$fc-var-core-button-arrows: "\f108";
$fc-var-core-loading-circle: "\f109";
$fc-var-cross: "\f10a";
$fc-var-expand: "\f10b";
$fc-var-flaticon-a-to-z: "\f10c";
$fc-var-flaticon-book-pile: "\f10d";
$fc-var-flaticon-cubes: "\f10e";
$fc-var-flaticon-desktop-pc: "\f10f";
$fc-var-flaticon-envelope: "\f110";
$fc-var-flaticon-info: "\f111";
$fc-var-flaticon-pin-on-map: "\f112";
$fc-var-info-comment: "\f11d";
$fc-var-info-language: "\f11e";
$fc-var-info-location: "\f113";
$fc-var-info-mail: "\f114";
$fc-var-info-phone: "\f115";
$fc-var-info-time: "\f116";
$fc-var-logo-facebook: "\f117";
$fc-var-logo-instagram: "\f118";
$fc-var-logo-linkedin: "\f119";
$fc-var-logo-twitter: "\f11a";
$fc-var-quote-left: "\f11b";
$fc-var-quote-right: "\f11c";
