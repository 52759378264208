/* Tables - Written by Timothy Phillips */

@import "_variables.sass";



/* tables */

table {
	margin-bottom: $size2;
	margin-top: $size2;
	font-size: $fontSize - 2px;
	line-height: $fontSize + 2px;
	td, th {
		padding: $size0 $size0;
		text-align: left;
		vertical-align: top;
		box-sizing: border-box;
		white-space: normal;
		@for $i from 1 through 10 {
			&.width-#{$i}0 {
				width: 100% / 10 * $i; } } }
	th {
		text-transform: uppercase;
		font-weight: bold; }
	tr {
		> *:first-child {
			padding-left: 0px; }
		> *:last-child {
			padding-right: 0px; } }
	thead th {
		vertical-align: bottom; } }

table.ruled, table.altrow, table.styled {
	box-sizing: border-box;
	min-width: 50%;
	position: relative;
	&.text-right {
		td {
			text-align: right; } }
	+ p.caption {
		@include caption($fa-var-table);
		margin-top: -$size1 + 2px;
		margin-bottom: $size2; }
	ul > li {
		background-position: 1px 1px; } }

table.ruled {
	tr {
		border-bottom: 1px solid rgba($colLinesGrey, 0.5); }

	> tbody:last-child > tr:last-child {
		border-bottom: 0px; } }


table.altrow, table.styled {

	tr {
		border: 0px;
		> *:first-child {
			padding-left: $size0; }
		> *:last-child {
			padding-right: $size0; } }
	thead, tfoot {
		background-color: mix($colBaseGrey, $colLinesGrey); }
	tbody {
		tr:nth-child(odd) {
			background-color: white; }
		tr:nth-child(even) {
			background-color: $colBaseGrey; }
		td[rowspan], th[rowspan] {
			background-color: white; } }
	&:after {
		display: block;
		content: "";
		border-bottom: 1px solid $colLinesGrey;
		height: $size1;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0; }

	@media print {
		tr {
			border-bottom: 1px solid $colLinesGrey; }
		> tbody:last-child > tr:last-child {
			border-bottom: 0px; } } }


table.compact-grid {
	border:  1px solid rgba($colLinesGrey, 0.5);
	tr {
		border-bottom: 1px solid rgba($colLinesGrey, 0.5);
		td, th {
			border-right: 1px solid rgba($colLinesGrey, 0.5); }
		> td:last-child, > th:last-child {
			border-right: 0px; } }
	> tbody:last-child > tr:last-child {
		border-bottom: 0px; }
	th, td {
		padding: 2px 3px;
		font-size: 14px; } }

table.padded {
	th, td {
		padding: $size1 $size1 !important; } }

table.text-center {
	th, td {
		text-align: center; } }


div.column-half, div.column-third, div.column-two-third {
	table.styled {
		min-width: 75%;
		margin-left: auto;
		margin-right: auto; } }

/* dl.table for faux table styles on definition list */

dl.table {
	@include clear;
	width: 100%;
	margin-bottom: $size2;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	> dt, > dd {
		padding: $size0 $size1;
		float: left;
		font-size: $fontSize - 2px;
		line-height: $fontSize + 2px;
		background-color: white;
		margin: 0px;
		> *:first-child {
			margin-top: 0px; }
		> *:last-child {
			margin-bottom: 0px; } }
	> dt {
		text-transform: uppercase;
		font-weight: bold;
		width: 25%; }
	> dd {
		width: 75%; }
	&.no-caps > dt {
		text-transform: none; }
	> *:nth-child(4n+1) {
		background-color: $colBaseGrey;
		+ dd {
			background-color: $colBaseGrey; } }
	&:after {
		display: block;
		content: "";
		width: 100%;
		border-bottom: 1px solid $colLinesGrey;
		height: $size1; }

	+ p.caption {
		@include caption($fa-var-table);
		margin-top: -$size2 + 2px;
		margin-bottom: $size2; } }


/* Matrix contact directory loader asset */
div.cdl_section table.table-size-99 {
	width: 100%;
	> tbody > tr {
		td {
			word-wrap: break-word;
			&:nth-child(1) {
				width: 25%; }
			&:nth-child(2) {
				width: 25%; }
			&:nth-child(3) {
				width: 15% !important; }
			&:nth-child(4) {
				width: 25%; }
			&:nth-child(5) {
				width: 10%; }
			@include mediaMedium {
				display: block;
				float: left;
				&:nth-child(1) {
					width: 50%; }
				&:nth-child(2) {
					width: 50%; }
				&:nth-child(3) {
					width: 30% !important; }
				&:nth-child(4) {
					width: 50%; }
				&:nth-child(5) {
					width: 20%; } } } } }
