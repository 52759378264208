/* Layout - Written by Timothy Phillips
 * columns, Grids and a large variety of override classes for margins, padding, width, responsive etc */

@import "_variables.sass";


/* simple columns with wrapper added by javascript such as .column-half*/

div[class^='column-'], div[class*=' column-'] {
	padding: $gridPad;
	box-sizing: border-box;
	float: left;
	//order: 0
	&.column-half {
		width: 50%;
		max-width: $pageContentMaxWidthPlus * 0.5 + $size2; }
	&.column-third {
		width: 33.3333%;
		max-width: $pageContentMaxWidthPlus * 0.333333 + $size2; }
	&.column-two-third {
		width: 66.6666%;
		max-width: $pageContentMaxWidthPlus * 0.666666 + $size2; }
	&.column-fifth {
		width: 20%;
		max-width: $pageContentMaxWidthPlus * 0.2 + $size2; }
	&.column-two-fifth {
		width: 40%;
		max-width: $pageContentMaxWidthPlus * 0.4 + $size2; }
	&.column-three-fifth {
		width: 60%;
		max-width: $pageContentMaxWidthPlus * 0.6 + $size2; }
	&.column-four-fifth {
		width: 80%;
		max-width: $pageContentMaxWidthPlus * 0.8 + $size2; }
	&.column-one-quarter, &.column-quarter {
		width: 25%;
		max-width: $pageContentMaxWidthPlus * 0.25 + $size2; }
	&.column-three-quarter {
		width: 75%;
		max-width: $pageContentMaxWidthPlus * 0.75 + $size2; }
	&.column-full {
		width: 100%; }
	&.column-desktop-right {
		float: right;
 }		//order: 1
	@include mediaMedium {
		width: 100% !important;
		float: none;
		margin-left: auto;
		margin-right: auto;
		+ * {
			clear: both; }

		// > h3:first-child
		// 	margin-top: $size1
		&.gap-right {
			padding-right: 100% / 12 * 1 !important;
			@include mediaLarge {
				padding-right: $gridPad !important; } }
		&.gap-left {
			padding-left: 100% / 12 * 1 !important;
			@include mediaLarge {
				padding-left: $gridPad !important; } }
		&.flex-align-center {
			align-self: center; } } }


div.column-group {
	// javascript adds the column group
	@include clear;
	padding: 0px;
	float: none;
	margin-top: $gridMargin;
	margin-bottom: $gridMargin;
	margin-left:  0 - $gridMargin / 2 !important;
	margin-right:  0 - $gridMargin / 2 !important;
	position: relative;
	z-index: $zIndex;
	&.flex {
		@include flexBox;
		> div {
			float: none; }
		&.align-center > div, > div.align-center {
			align-self: center;
			text-align: center; }
		@include mediaMedium {
			@include flexNone; } }
	@include mediaMedium {
		padding: 0px;
		margin: $size2 0; }
	> div {
		@include childNoMargins;
		padding: ($gridPad * 2) $gridPad 0 $gridPad;

		@include mediaMedium {
			//padding: 0px
			margin-bottom: $size2;
			&:last-child {
				margin-bottom: 0px; } } }

	> div:first-child, > div:first-child + div {
		padding-top: 0px; }
	> div:first-child.column-third + div.column-third + div.column-third {
		padding-top: 0px; }
	> div:first-child.column-quarter + div.column-quarter + div.column-quarter {
		padding-top: 0px;
		+ div.column-quarter {
			padding-top: 0px; } } }


/* ul.grid-x-column - Simple list based grid layouts - used as a base for MANY styles */

@mixin gridWidth($cols) {
	width: 100% / 12 * $cols; }



.grid-1-column, .grid-3-column, .grid-2-column, .grid-4-column, .grid-5-column, .grid-6-column {
	@include clear;
	@include flexBox;
	margin: $size2 (-$gridPad) $size2 (-$gridPad);


	> li {
		background-image: none;
		> .fill-parent {
			width: 100%;
			height: 100%; } }
	&.expand-all > li > * {
		max-width: 100% !important;
		min-width: 100px !important;
		width: 100%;
		height: 100%; }
	> * {
		font-size: $fontSize;
		flex-grow: 0;
		//vertical-align: top
		box-sizing: border-box;
		align-self: stretch;
		padding: ($gridPad * 2) $gridPad 0 $gridPad;
		margin: 0px;
		> img {
			max-width: 100%;
			margin-bottom: $size1; } }
	&.padding-full {
		> * {
			padding: ($gridPad * 2) $gridPad 0 $gridPad; } }
	&.padding-half {
		> * {
			padding: $gridPad ($gridPad / 2) 0 ($gridPad / 2); } }
	&.padding-none {
		> * {
			padding: 0 !important; } }
	&.align-left {
		justify-content: flex-start; } }




.grid-1-column > * {
	width: 100%;
	&:first-child {
		padding-top: 0px; }
	@include mediaMedium {
		width: 50%;
		&:nth-child(-n+2) {
			padding-top: 0px; } }
 }	//+mediaSmall

.grid-2-column > * {
	width: 50%;
	&:nth-child(-n+2) {
		padding-top: 0px; }
 }	//+mediaSmall

.grid-3-column > * {
	width: 33.3333%;
	&:nth-child(-n+3) {
		padding-top: 0px; }
 }	//+mediaMedium

.grid-4-column > * {
	width: 25%;
	&:nth-child(-n+4) {
		padding-top: 0px; }
	@include mediaLarge {
		width: 50%;
		padding-top: $size2;
		&:nth-child(-n+4) {
			padding-top: $size2; }
		&:nth-child(-n+2) {
			padding-top: 0px; } }
 }	//+mediaMedium

.grid-5-column > * {
	width: 20%;
	&:nth-child(-n+5) {
		padding-top: 0px; }
	@include mediaLarge {
		width: 33.3333%;
		&:nth-child(-n+5) {
			padding-top: $size2; }
		&:nth-child(-n+3) {
			padding-top: 0px; } }
	@include mediaMedium {
		width: 50%;
		&:nth-child(-n+3) {
			padding-top: $size2; }
		&:nth-child(-n+2) {
			padding-top: 0px; } }
 }	//+mediaTiny


.grid-6-column > * {
	width: 16.6666%;
	&:nth-child(-n+6) {
		padding-top: 0px; }
	@include mediaMedium {
		width: 33.3333%;
		&:nth-child(-n+6) {
			padding-top: $size2; }
		&:nth-child(-n+3) {
			padding-top: 0px; } }
	@include mediaSmall {
		width: 50%;
		&:nth-child(-n+3) {
			padding-top: $size2; }
		&:nth-child(-n+2) {
			padding-top: 0px; } } }

#legacy-browser {
	.grid-3-column, .grid-2-column, .grid-4-column, .grid-5-column .grid-6-column {
		@include flexNone;
		> * {
			float: left; } } }

@include mediaSmall {
	.grid-1-column, .grid-2-column {
		@include flexNone;
		> * {
			width: 100%;
			margin: auto;
			float: none;
			&:not(:first-child) {
				padding-top: $size2; }
			> .fill-parent {
				height: auto !important; } } } }

@include mediaMedium {
	.grid-3-column, .grid-4-column {
		@include flexNone;
		> * {
			width: 100%;
			margin: auto;
			float: none;
			&:not(:first-child) {
				padding-top: $size2; }
			> .fill-parent {
				height: auto !important; } } } }

@include mediaTiny {
	.grid-5-column {
		@include flexNone;
		> * {
			width: 100%;
			margin: auto;
			float: none;
			&:not(:first-child) {
				padding-top: $size2; }
			> .fill-parent {
				height: auto !important; } } } }



/* .grid layout - a lot of precice control. Typically for less-standard developer-crafted layouts */

.grid {
	@include clear;
	@include flexBox;
	margin-left: -$gridPad;
	margin-right: -$gridPad;

	position: relative;
	z-index: $zIndex + 1;

	> * {
		box-sizing: border-box; }
	img {
		max-width: 100%; }
	&.debug > [class^="grid-"]:hover {
		position: relative;
		&:before {
			content: " ";
			display: block;
			position: absolute;
			box-shadow: 0px 0px 10px pink;
			top: $gridPad;
			left: $gridPad;
			bottom: $gridPad;
			right: $gridPad; } }

	> [class^="grid-"],
	> [class*=" grid-"] {
		//+flexItem
		padding: $gridPad;
		position: relative;
		> div {
			height: 100%; }
		> *:last-child {
			margin-bottom: 0px; }
		> *:first-child {
			margin-top: 0px; }
		&.box {
			padding: $gridPad * 2;
			> * {
				position: relative;
				z-index: $zIndex + 2; }
			&:before {
				z-index: $zIndex - 1;
				content: " ";
				display: block;
				position: absolute;
				top: $gridPad;
				bottom: $gridPad;
				left: $gridPad;
				right: $gridPad;
				background-color: $colBaseGrey; } } }
	&.grid-tight {
		margin-left: -$gridPadTight;
		margin-right: -$gridPadTight;
		> [class^="grid-"],
		> [class*=" grid-"] {
			padding: $gridPadTight;
			&.box {
				padding: $gridPad * 2;
				&:before {
					top: $gridPadTight;
					bottom: $gridPadTight;
					left: $gridPadTight;
					right: $gridPadTight; } } } }
	// combine input-row with form.lite fieldset.combo
	&.input-combo {
		margin-left: auto;
		margin-right: auto;
		> [class^="grid-"],
		> [class*=" grid-"] {
			padding: 0px; }
		> li {
			white-space: nowrap; } } }

ul.grid {
	list-style: none;
	> li {
		background: none;
		margin: 0px; } }




.grid {
	.margin-right-1g {
		margin-right: (100% / 12 * 1); }
	.margin-left-1g {
		margin-left: (100% / 12 * 1); }


	@for $i from 1 through 12 {
		> .grid-#{$i} {
			width: (100% / 12 * $i); } }

	@include mediaLarge {
		@for $i from 1 through 12 {
			> .grid-tablet-#{$i} {
				width: (100% / 12 * $i); } }
		.grid-tablet-0 {
			display: none; }
		.margin-tablet-none {
			margin-right: 0px; } }

	@include mediaMedium {
		@for $i from 1 through 12 {
			> .grid-mobile-#{$i} {
				width: (100% / 12 * $i); } }
		.grid-mobile-0 {
			display: none; }
		.margin-mobile-none {
			margin-right: 0px; } } }


@include mediaLarge {
	@for $i from 1 through 9 {
		.grid-tablet-order-#{$i} {
			order: $i - 10 !important; } } }

@include mediaMedium {
	@for $i from 1 through 9 {
		.grid-mobile-order-#{$i} {
			order: $i - 10 !important; } } }


@for $i from 1 through 12 {
	.width-#{$i}g {
		width: 100% / 12 * $i;
		box-sizing: border-box; } }

@each $i in 2, 4, 6, 8, 12 {
	@include mediaMedium {
		.mobile-width-#{$i}g {
			width: 100% / 12 * $i;
			box-sizing: border-box; } } }

#legacy-browser .grid {
	display: block;
	> [class^="grid-"],
	> [class*="grid-"] {
		float: left; } }

/* generic show/hide for tablet/mobile/desktop such as .desktop-hide and .mobile-show */
@include mediaHuge {
	.desktop-hide {
		display: none !important; } }

@include mediaLarge {
	.tablet-hide {
		display: none !important; } }

@include mediaMinLarge {
	.tablet-show {
		display: none !important; } }

@include mediaMedium {
	.mobile-hide {
		display: none !important; } }

@include mediaMinMedium {
	.mobile-show {
		display: none !important; } }

/* simple block alignments such as .block-center and .block-indent*/

.block-center {
	padding-left: $size1;
	padding-right: $size1;
	margin: $size2 auto;
	max-width: $pageContentMaxWidth / 2;
	@include mediaSmall {
		margin-right: 0;
		margin-left: 0; } }

.block-indent {
	padding: 0 $size4;
	margin: $size2 auto;
	@include mediaSmall {
		padding: 0 $size4; } }

// @for $i from 1 through 6
// 	.indent-#{$i}
// 		margin-left: $size2 * $i !important


.block-center, .block-indent {
	> *:first-child {
		margin-top: 0px; }
	> *:last-child {
		margin-bottom: 0px; } }

/* margin overrides such as .margin-top-none and .margin-large */

.margin-none {
	margin: 0 !important; }

.margin-tiny {
	margin-top: $size0 !important;
	margin-bottom: $size0 !important; }
.margin-small {
	margin-top: $size1 !important;
	margin-bottom: $size1 !important; }
.margin-normal {
	margin-top: $size2 !important;
	margin-bottom: $size2 !important; }
.margin-large {
	margin-top: $size4 !important;
	margin-bottom: $size4 !important; }
.margin-huge {
	margin-top: $size6 !important;
	margin-bottom: $size6 !important; }

.margin-top-none {
	margin-top: 0 !important; }
.margin-top-tiny {
	margin-top: $size0 !important; }
.margin-top-small {
	margin-top: $size1 !important; }
.margin-top {
	margin-top: $size2 !important; }
.margin-top-large {
	margin-top: $size4 !important; }
.margin-top-huge {
	margin-top: $size4 !important; }

.margin-bottom-negative {
	margin-bottom: -$size2 !important; }
.margin-bottom-none {
	margin-bottom: 0 !important; }
.margin-bottom-tiny {
	margin-bottom: $size0 !important; }
.margin-bottom {
	margin-bottom: $size2 !important; }
.margin-bottom-small {
	margin-bottom: $size1 !important; }
.margin-bottom-large {
	margin-bottom: $size4 !important; }
.margin-bottom-huge {
	margin-bottom: $size6 !important; }

/* padding overrides such as .padding-none and .padding-bottom-large */

.padding-none {
	padding: 0px !important; }
.padding-tiny {
	padding: $size0 !important; }
.padding-small {
	padding: $size1 !important; }
.padding-medium {
	padding: $size2 !important; }
.padding-large {
	padding: $size4 !important; }
.padding-huge {
	padding: $size6 !important; }

.padding-top-none {
	padding-top: 0 !important; }
.padding-top-small {
	padding-top: $size1 !important; }
.padding-top {
	padding-top: $size2 !important; }
.padding-top-large {
	padding-top: $size4 !important; }

.padding-bottom-none {
	padding-bottom: 0 !important; }
.padding-bottom-small {
	padding-bottom: $size1 !important; }
.padding-bottom {
	padding-bottom: $size2 !important; }
.padding-bottom-large {
	padding-bottom: $size4 !important; }

.padding-vertical-none {
	padding-top: 0 !important;
	padding-bottom: 0 !important; }


/* block width sizing such as .width-full and .width-max-tablet */

.size-full {
	max-width: 100% !important;
	min-width: 100px !important;
	width: 100%;
	height: 100%; }


.width-full, .width-max {
	width: 100% !important;
	max-width: 100%  !important; }

.width-max-desktop, .width-max-large, .width-max-semi-large, .width-max-tablet, .width-max-medium, .width-max-small, .width-max-mobile, .width-max-tiny, .width-large, .width-semi-large, .width-medium-large, .width-medium, .width-small, .width-tiny {
	margin-left: auto !important;
	margin-right: auto !important; }

.width-max-desktop, .width-max-large {
	max-width: $mediaLargeWidth - ($pageGutter * 2); }


.width-max-semi-large, .width-max-medium-large {
	max-width: $mediaSemiLargeWidth - ($pageGutter * 2); }

.width-max-tablet {
	max-width: 768px; }

.width-max-medium {
	max-width: $mediaMediumWidth - ($pageGutter * 2); }

.width-max-small {
	max-width: $mediaSmallWidth - ($pageGutterSmall * 2); }

.width-max-mobile {
	max-width: 320px; }

.width-max-tiny {
	max-width: 320 - ($pageGutterTiny * 2); }





.width-enormous {
	width: $mediaEnormousWidth - ($pageGutter * 2) !important;
	max-width: 100% !important; }

.width-huge {
	width: $mediaHugeWidth - ($pageGutter * 2) !important;
	max-width: 100% !important; }

.width-large {
	width: $mediaLargeWidth - ($pageGutter * 2) !important;
	max-width: 100% !important; }

.width-semi-large, .width-medium-large {
	width: $mediaSemiLargeWidth - ($pageGutter * 2) !important;
	max-width: 100% !important; }

.width-medium {
	width: $mediaMediumWidth - ($pageGutter * 2) !important;
	max-width: 100% !important; }

.width-small {
	width: $mediaSmallWidth - ($pageGutterSmall * 2) !important;
	max-width: 100% !important; }

.width-tiny {
	width: 320 - ($pageGutterTiny * 2) !important;
	max-width: 100% !important; }


/* .margin-flush-header or footer to push content up or down flush with the header or footer */

.margin-flush-footer {
	margin-bottom: -$pageGutterBottom !important; }

.margin-flush-header {
	margin-top: -$pageGutterTop !important; }

/* z-index tweaks */

.z-index-high {
	z-index: $zIndex + 50 !important;
	position: relative; }
.z-index-middle {
	z-index: $zIndex !important;
	position: relative; }
.z-index-low {
	z-index: $zIndex - 50 !important;
	position: relative; }


