/* Central content block - Written by Timothy Phillips */

@import "_variables";


/* breadcrumbs for old and style-version-two */

div.content ol#uwacore-breadcrumbs {
	margin: -$size0 0 ($size1) 0;
	list-style: none;
	padding: 0px;
	color: $colTextGrey;
	font-size: $fontSize - 2px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	@include clear;
	li {
		display: inline-block;
		padding: 0px $size1 0 0;
		position: relative;
		&:after {
			content: $fa-var-angle-right;
			position: absolute;
			width: $size1;
			text-align: center;
			right: 0;
			top: 0;
			font-family: $fontFA; }
		&:last-child {
			padding-right: 0px;
			&:after {
				display: none; } } }
	+ * {
		margin-top: 0px; } }

body.header-style-two ol#uwacore-breadcrumbs, body.style-version-two ol#uwacore-breadcrumbs {
	background-color: darken($colBlue, 5%);
	margin: 0px;
	padding: 0px;
	text-shadow: none;
	font-size: 0px;
	line-height: $size2;
	li {
		padding: $size0 $size1h $size0 $size0;
		margin: 0px;
		display: inline-block;
		font-size: $fontSize - 2px;
		position: relative;
		color: darken(white, 5%);
		&:after {
			content: $fa-var-angle-right;
			position: absolute;
			width: $size1;
			text-align: center;
			font-family: $fontFA;
			right: 0px;
			top: 50%;
			line-height: $size2;
			margin-top: -$size1;
			color: darken(white, 5%); }
		&:nth-child(1) {
			// we don't want the UWA home
			display: none; }
		&:nth-child(2) {
			background-color: lighten($colBlue, 5%);
			padding-left: $size2;
			padding-right: $size1;
			position: relative;
			margin-right: $size2;
			&:after {
				display: inline-block;
				position: absolute;
				content: "";
				left: 100%;
				top: 0px;
				bottom: 0px;
				width: 0;
				height: 0;
				margin-top: 0px;
				border-style: solid;
				border-width: $size1h 0 $size1h $size1h;
				border-color: transparent transparent transparent lighten($colBlue, 5%); } }
		&:last-child:after {
			display: none; } }




	@include mediaSmall {
		display: none; }
	a {
		@include link(0,1, white); } }

body.no-breadcrumbs, body.landing-page:not(.show-breadcrumbs) {
	ol#uwacore-breadcrumbs {
		display: none; }
	#centrecontainer > div.contentwrapper:first-child {
		padding-top: $pageGutterTop;
		@include mediaMedium {
			padding-top: $pageGutterTop; } } }




// hide older elements
#navigation,
#pagebanner,
ol#breadcrumbs,
#mobile-navigation {
	display: none; }

/* #centercontainer in normal and style-version-two ToDo: clean-up*/

#centrecontainer {
	//overflow-x: hidden
	//overflow-y: visible
	z-index: $zIndex;
	position: relative;
	margin-top: 0px;
	overflow-x: hidden;
	> div.contentwrapper:first-child {
		background: url('../img/bgs/body-top.png') center top repeat-x white; }

	// > div.widewrapper
	// 	width: 100%
	// 	background-size: cover
	// 	background-position: center top
	// 	background-repeat: no-repeat
	// 	outline: 1px dotted red
	// 	> div
	// 		outline: 1px dotted blue
	> div.contentwrapper {
		padding-top: $pageGutterTop;
		width: $pageContentMaxWidth + ($pageGutter * 2);
		@include clear;
		@include fluidPageContainer;
		//padding: $pageGutterTop $size2 $pageGutterBottom $size2
		//padding-top: $pageGutterTop
		padding-bottom: $pageGutterBottom;
		// +mediaLarge
		// 	max-width: $pageContentMaxWidth + ($pageGutterLarge * 2)
		// 	padding: $size2 $pageGutterLarge $pageGutterBottom $pageGutterLarge
		// 	// padding-top: $size2
		// 	// padding-bottom: $pageGutterBottom
		// 	// padding-left: calc(12px + 5 * (100vw - 40rem) / 40)
		// +mediaMedium
		// 	padding: $size2 $pageGutterMedium $pageGutterBottom $pageGutterMedium
		// +mediaSmall
		// 	padding: $size2 $pageGutterSmall $pageGutterBottom $pageGutterSmall
		// +mediaTiny
		// 	padding: $size2 $pageGutterTiny $pageGutterBottom $pageGutterTiny
		> div.content {
			min-height: 300px;

			//+childNoMargins
			//> div[id^="content_div_"]:first-child > *:first-child
			//	margin-top: 0px
			@include mediaLarge {
				//width: 100%
				margin: 0px auto;
				background-size: auto 200px; }
			@include mediaSmall {
				background-size: auto 150px; }
			@include mediaTiny {
				background-size: auto 100px; }
			> *:first-child {
				margin-top: 0px; }
			> *:last-child {
				margin-bottom: 0px; }

			div[id^="new_div_"]:first-child > *:first-child {
				margin-top: 0px; } } } }

body.header-none #centrecontainer > div.contentwrapper:first-child {
	background-image: none; }

body:not(.style-version-two) #centrecontainer > div.contentwrapper {
	padding-top: $pageGutterTop;
	> div.content {
		@include childNoMargins;
		> div[id^="content_div_"]:first-child > *:first-child {
			margin-top: 0px; } } }



body.page-wide, body.style-version-two {
	#centrecontainer {
		> div.contentwrapper {
			width: $pageContentMaxWidthPlus + ($pageGutterSmall * 2);
			@include fluidPageContainerWide; }
		// 	max-width: $pageContentMaxWidthPlus + ($pageGutter * 2)
		// > div.contentwrapper > div.content
		// 	width: $pageContentMaxWidthPlus + ($pageGutter * 2)
		// 	+mediaHuge()
 } }		// 		width: 100%


/* Twocol layout with rightcol first in code for IE8 compatibility - DISCONTINUED IN FSEP */

body:not(.style-version-two) div.two-col {
	// newcore style reverse order with fixed pixel width
	margin-top: $size2;
	@include clear;
	div.left-col {
		//margin-right: 235px
		float: left;
		width: 100% / 12 * 9;
		padding-right: $size2 - 1;
		border-right: 1px solid #d8d8d8;
		box-sizing: border-box;
		> *:first-child {
			margin-top: 0px; }
		> div:first-child > h3:first-child {
			margin-top: 0px; } }
	div.right-col {
		box-sizing: border-box;
		padding-left: $size1;
		float: right;
		//width: 220px
		width: 100% / 12 * 3;
		> *:first-child {
			margin-top: 0px; }
		> div:first-child > h3:first-child {
			margin-top: 0px; }

		//.text-center
 }		//	text-align: left
	div.full-col {
		clear: both;
		> *:first-child {
			margin-top: 0px; } }
	&.no-divider {
		div.left-col {
			border-right: none;
			padding-right: $size1; } }
	div.two-col {
		div.left-col {
			width: 100% / 12 * 7; }
		div.right-col {
			width: 100% / 12 * 5; } } }


@include mediaLarge() {

	// use flexbox on mobiles to swap the order
	body:not(.style-version-two) div.two-col {
		display: -webkit-box; //old ios
		-webkit-box-orient: vertical;
		display: -webkit-flex; //new ios
		-webkit-flex-direction: column;
		display: flex; // standards
		flex-direction: column;
		margin-bottom: $size2;

		> div.left-col {
			-webkit-box-ordinal-group: 1;
			-webkit-order: 1;
			order: 1;
			margin-right: 0px;
			border-right: 0px;
			padding-right: 0px;
			width: 100%;
			margin-top: 0;
			margin-bottom: $size1;
			> *:last-child {
				margin-bottom: 0px; } }
		> div.full-col {
			-webkit-box-ordinal-group: 2;
			-webkit-order: 2;
			order: 2;
			margin-bottom: $size1; }
		> div.right-col {
			-webkit-box-ordinal-group: 3;
			-webkit-order: 3;
			order: 3;
			float: none;
			width: 100%;
			padding: $size1 0;
			border-top: 1px dotted #d8d8d8;
			border-bottom: 1px dotted #d8d8d8;
			margin-bottom: $size1;
			> *:last-child {
				margin-bottom: 0px; } }
		div.two-col {
			div.right-col, div.left-col {
				width: 100%; } }
		&.no-divider {
			div.left-col {
				padding-right: 0; } } } }


body.style-version-two {
	div.two-col div.right-col {
		display: none; }
	&.preserve-rightcol div.two-col div.right-col,
	div.two-col div.right-col.preserve {
		@include childNoMargins;
		background-color: $colBaseGrey;
		display: block;
		margin-left: auto !important;
		margin-right: auto !important;
		margin-top: $size4;
		max-width: 500px;
		padding: $size2;
		border-left: 3px solid $colBlue;
		h3 {
			padding: 0px;
			border: none;
			line-height: 1.1; } } }



// body.style-version-two:not(.preserve-rightcol) div.two-col div.right-col:not(.preserve)
// 	display: none
/// div.left-col
/// 	border: none
/// 	width: calc(100% - 400px)
/// div.right-col
/// 	width: 400px
/// 	border-top: none
/// 	border-bottom: none
/// +mediaLarge
/// 	div.left-col
/// 		width: 100%
/// 		padding: 0px
/// 		margin-bottom: 0px
/// 	div.right-col
/// 		margin-top: $size2
/// 		max-width: 100%

body:not(.style-version-two) {
	div.furtherinfo,
	div.right-col {
		font-size: $fontSizeRightCol;
		line-height: $fontLineHeightRightCol;
		color: $colText;
		font-family: $fontSans;
		p {
			margin: 0 0 $size1 0; }
		h3 {
			font-family: $fontSans;
			font-size: 19px;
			line-height: 24px;
			text-transform: uppercase;
			color: #937800;
			margin: $size2 0 $size1 0;
			font-weight: 300;
			letter-spacing: 0.1em;
			border-bottom: 1px solid $colLinesGrey;
			//&.overline //studyat
			//	border-top: 1px solid $colLinesGrey
			//	border-bottom: 0px
 }			//	margin: $size1 0
		h4 {
			font-family: $fontSans;
			font-size: 15px;
			font-weight: 400;
			text-transform: uppercase;
			letter-spacing: 0.065em;
			color: $colTextGrey;
			line-height: 18px;
			margin: $size1h 0 $size1 0; }
		h5 {
			font-family: $fontSans;
			font-size: 15px;
			font-weight: 500;
			color: $colText;
			line-height: 18px;
			margin: $size1 0 $size1 0; }
		.highlight-box {
			padding: $size0;
			font-size: 14px;
			&:before {
				display: none; }
			&:first-child {
				margin-top: 0px; }
			&:last-child {
				margin-bottom: 0px; } }


		blockquote {
			padding-left: 0px;
			padding-right: 0px;
			font-size: 15px;
			line-height: 20px;
			&:before {
				line-height: 1em;
				padding-bottom: $size1; }
			//&:after
 } } }			///font-size: 14px

/* Full page as iframe modal - drop header, footer */

body.modal-content {
	background-color: white;
	padding: $size2;
	@include mediaMedium {
		padding: $size1; }
	#sitehomelink {
		display: none; }
	#pagetitle {
		margin: 0px 0px $size2 0px;
		text-align: left;
		@include fluidText($fluidH2, $fluidH2Max); }
	#centrecontainer > div.contentwrapper {
		background: none !important;
		padding: 0px !important; } }
