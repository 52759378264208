//
// Icon Font: fontcustom-dcs
//


[data-icon]:before { content: attr(data-icon); }

[data-icon]:before,
.fc-angle-down:before,
.fc-angle-left:before,
.fc-angle-right:before,
.fc-angle-up:before,
.fc-arrow-right:before,
.fc-close-cross:before,
.fc-core-bullet:before,
.fc-core-bullet-link:before,
.fc-core-button-arrows:before,
.fc-core-loading-circle:before,
.fc-cross:before,
.fc-expand:before,
.fc-flaticon-a-to-z:before,
.fc-flaticon-book-pile:before,
.fc-flaticon-cubes:before,
.fc-flaticon-desktop-pc:before,
.fc-flaticon-envelope:before,
.fc-flaticon-info:before,
.fc-flaticon-pin-on-map:before,
.fc-info-comment:before,
.fc-info-language:before,
.fc-info-location:before,
.fc-info-mail:before,
.fc-info-phone:before,
.fc-info-time:before,
.fc-logo-facebook:before,
.fc-logo-instagram:before,
.fc-logo-linkedin:before,
.fc-logo-twitter:before,
.fc-quote-left:before,
.fc-quote-right:before {
  display: inline-block;
  font-family: "fontcustom-dcs";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.fc-angle-down:before { content: "\f100"; }
.fc-angle-left:before { content: "\f101"; }
.fc-angle-right:before { content: "\f102"; }
.fc-angle-up:before { content: "\f103"; }
.fc-arrow-right:before { content: "\f104"; }
.fc-close-cross:before { content: "\f105"; }
.fc-core-bullet:before { content: "\f106"; }
.fc-core-bullet-link:before { content: "\f107"; }
.fc-core-button-arrows:before { content: "\f108"; }
.fc-core-loading-circle:before { content: "\f109"; }
.fc-cross:before { content: "\f10a"; }
.fc-expand:before { content: "\f10b"; }
.fc-flaticon-a-to-z:before { content: "\f10c"; }
.fc-flaticon-book-pile:before { content: "\f10d"; }
.fc-flaticon-cubes:before { content: "\f10e"; }
.fc-flaticon-desktop-pc:before { content: "\f10f"; }
.fc-flaticon-envelope:before { content: "\f110"; }
.fc-flaticon-info:before { content: "\f111"; }
.fc-flaticon-pin-on-map:before { content: "\f112"; }
.fc-info-comment:before { content: "\f11d"; }
.fc-info-language:before { content: "\f11e"; }
.fc-info-location:before { content: "\f113"; }
.fc-info-mail:before { content: "\f114"; }
.fc-info-phone:before { content: "\f115"; }
.fc-info-time:before { content: "\f116"; }
.fc-logo-facebook:before { content: "\f117"; }
.fc-logo-instagram:before { content: "\f118"; }
.fc-logo-linkedin:before { content: "\f119"; }
.fc-logo-twitter:before { content: "\f11a"; }
.fc-quote-left:before { content: "\f11b"; }
.fc-quote-right:before { content: "\f11c"; }

$fc-var-angle-down: "\f100";
$fc-var-angle-left: "\f101";
$fc-var-angle-right: "\f102";
$fc-var-angle-up: "\f103";
$fc-var-arrow-right: "\f104";
$fc-var-close-cross: "\f105";
$fc-var-core-bullet: "\f106";
$fc-var-core-bullet-link: "\f107";
$fc-var-core-button-arrows: "\f108";
$fc-var-core-loading-circle: "\f109";
$fc-var-cross: "\f10a";
$fc-var-expand: "\f10b";
$fc-var-flaticon-a-to-z: "\f10c";
$fc-var-flaticon-book-pile: "\f10d";
$fc-var-flaticon-cubes: "\f10e";
$fc-var-flaticon-desktop-pc: "\f10f";
$fc-var-flaticon-envelope: "\f110";
$fc-var-flaticon-info: "\f111";
$fc-var-flaticon-pin-on-map: "\f112";
$fc-var-info-comment: "\f11d";
$fc-var-info-language: "\f11e";
$fc-var-info-location: "\f113";
$fc-var-info-mail: "\f114";
$fc-var-info-phone: "\f115";
$fc-var-info-time: "\f116";
$fc-var-logo-facebook: "\f117";
$fc-var-logo-instagram: "\f118";
$fc-var-logo-linkedin: "\f119";
$fc-var-logo-twitter: "\f11a";
$fc-var-quote-left: "\f11b";
$fc-var-quote-right: "\f11c";


$fontCustomIcons: "fontcustom-dcs";

i.fc:before {
	font-family: $fontCustomIcons;
	display: inline-block;
}

@mixin uwaIconBefore($content) {
	&:before {
		content: $content;
		font-family: $fontCustomIcons;
		display: inline-block;
		text-decoration: none;
	}
}


