@import "_fa_variables";

@import "_fontcustom-vars";

/* Official parameters - don't modify */

// Official
$sizeFontRoot: 16;
$sizePadingRoot: 12;
$sizeBase: 12px;

$colBlue: #27348b;
$colGold: #E2B600;
$colSilver: #ececec;

$colBaseGrey: #ececec;
$colBaseGreyDarker: #DDDDDD;

$colBaseLight: #e2edfa;
$colBaseLighter: #f5f8fc;

$colText: #464646;
$colHeader: #27348b;
$colHeader4: #313764;
$colHeader6: #464646;
$colBlockquote: #313764;
$colByline: #737373;
$colIntroduction: #464646;
$colTextLink: #0e55b5;
$colTextLinkVisited: #773678;
$colLinkCTA: #0e55b5;

$colDarkBase: $colBlue;
$colDarkText: #FFFFFF;

// Secondary colour palet
$colMaroon: #a92247;
$colCrimson: #de2851;
$colTomato: #e43622;
$colMaria: #e84e0f;
$colAutum: #f49700;
$colAmber: #fdc300;
$colSeaweed: #265521;
$colApple: #3e8638;
$colSushi: #a2c03b;
$colMidnight: #003869;
$colDenim: #1488ca;
$colViking: #48bed8;
$colBossanova: #462e51;
$colOrchid: #912773;
$colPlum: #c46ca9;

// backgrounds
$colBgSky: #e2edfa;
$colBgSmoke: #f5f8fc;
$colBgGrey: #ececec;
$colBgGreyDark: #dddddd;

$fluidBody: 16;
$fluidBodyMax: 18;
$fluidBlockquote: 21;
$fluidBlockquoteMax: 26;
// not official?
$fluidBlockquoteSmall: 18;
// not official?
$fluidBlockquoteMaxSmall: 21;
$fluidByline: 16;
$fluidCTAText: 16;
$fluidCTATextMax: 18;
$fluidFormLabel: 17;
$fluidFormLabelMax: 19;
$fluidH1: 30;
$fluidH1Label: 15;
$fluidH1Max: 50;
$fluidH2: 28;
$fluidH2Max: 40;
$fluidH3: 23;
$fluidH3Max: 28;
$fluidH4: 19;
$fluidH4Max: 22;
$fluidH5: 17;
$fluidH5Max: 19;
$fluidH6: 16;
$fluidH6Max: 18;
$fluidImageButton: 19;
$fluidImageButtonMax: 25;
$fluidIntroduction: 18;
$fluidIntroductionMax: 21;
$fluidOverview: 16;
$fluidOverviewMax: 23;
$fluidTabLabel: 15;
$fluidTableHeader: 15;

$fontSizeCTAButton: 19px;
$fontSizeByline: 16px;
$fontSizeTab: 15px;

$lineHeight: 1.5;
$lineHeightBlockquote: 1.4;
$lineHeightByline: 1.2;
$lineHeightByline: 1.2;
$lineHeightCTAButton: 1.2;
$lineHeightCTAText: 1.5;
$lineHeightFormLabel: 1.4;
$lineHeightH1: 1.2;
$lineHeightH1Label: 1.2;
$lineHeightH2: 1.2;
$lineHeightH3: 1.3;
$lineHeightH4: 1.3;
$lineHeightH5: 1.4;
$lineHeightH6: 1.5;
$lineHeightImageButton: 1.2;
$lineHeightIntroduction: 1.5;
$lineHeightOverview: 1.5;
$lineHeightTableHeader: 1.2;
$lineHeightTab: 1.2;




/* normal paramaters */

$animSlow: 5s;
$animSpeed: 0.5s;
$animSpeedHover: 100ms;
$col-blue: #69C4D9;
$col-sandstone: #EFE8CC;
$colAlv: #e0ad00;
$colAlvAlt: #DEB408;
$colArt: #a2559c;
$colArtAlt: #9B5BA4;
$colBaseBlue: #27348b;


$colBaseLightGrey: #F6F6F6;


$colTab: $colDarkBase;
$colTabAlt: #0e55b5;
$colTabText: white;

$colBlueDark: #232d6e;
$colBlueDarkAlt: #a5aeed;
$colBlueDarkHead: #a5aeed;
$colBlueDarkLine: #13193c;
$colBlueText: #FFFFFF;
$colBus: #0b93d2;
$colBusAlt: #007CB1;
$colCodeBase: #EAF7FA;
$colEcm: #c25813;
$colEcmAlt: #CA440F;
$colEdu: #0094aa;
$colEduAlt: #00727E;
$colFacebook: #3B5998;
$colFacultyArc: #e0ad00;
$colFacultyArt: #a2559c;
$colFacultyBus: #0b93d2;
$colFacultyEdu: #0094aa;
$colFacultyEng: #c25813;
$colFacultyInd: #e42313;
$colFacultyLaw: #4e5ca7;
$colFacultyMed: #980a2c;
$colFacultySci: #678816;
$colFlickr: #FD1980;

$colGoldBorder: mix($colGold, white, 20%);
$colGoldText: #27348b;



$colInd: #e42313;
$colIndAlt: #e42313;
$colInstagram: #125688;

$colLaw: #4e5ca7;
$colLawAlt: #4B63AE;
$colLinesGrey: #D8D8D8;
$colLinkedIn: #27348B;
$colMed: #980a2c;
$colMedAlt: #A2002E;
$colPaleBlue: #eaedff;
$colSci: #678816;
$colSciAlt: #41851C;

// mushroom brown
$colStudyat: #666262;

$colTextGrey: #737373;
$colTextLight: #868686;

$colTwitter: #55ACEE;
$colYoutube: #BB0000;

$colAlert: mix(black, $colCrimson, 20%);
$colAlertBase: mix($colCrimson, white, 12%);
$colWarning: mix(black, $colAmber, 20%);
$colWarningBase: mix($colAmber, white, 12%);
$colSuccess: mix(black, $colSushi, 20%);
$colSuccessBase: mix($colSushi, white, 12%);
$colInfo: mix(black, $colViking, 20%);
$colInfoBase: mix($colViking, white, 12%);

$fontCustom: "fontcustom-dcs";
$fontFA: 'FontAwesome';
$fontLineHeight: 24px;
$fontLineHeightRightCol: 18px;
$fontMono: 'UbuntuMono', monospace;
$fontSans: 'Source Sans Pro', Arial, sans-serif;
$fontSerif: Georgia, serif;

$fontSize: 16px;
$fontSizeH3: 28px;
$fontSizeH4: 22px;
$fontSizeH5: 18px;
$fontSizeH6: 17px;

$fontSizeRightCol: 14px;
$fontSizeSmaller: $fontSize - 1;
$fontUWA: 'UWA Regular', 'UWA', Georgia, serif;

$formBase: $colBaseLightGrey;
$formBaseDisabled: $colLinesGrey;
$formBaseDisabledText: mix($colText, $colLinesGrey);
$formBlue: $colBlue;
$formBlueLight: #4753a5;
$formBlueText: white;
$formFauxButtonWidth: 50px;
$formFontSize: $fontSize;
$formGold: $colGold;
$formGoldLight:  mix($colGold, $colSilver, 75%);
$formGoldText: #27348B;
$formGrey: $colSilver;
$formHeader: $colSilver;
$formHeaderSize: $fontSize * 1.2;
$formHeaderText: $colBlue;
$formInputHeight: 40px;
$formInputHeightLarge: 60px;
$formLineHeight: $fontSize + 4;
$formLines: #dbdbdb;
$formLinesHover: mix($formLines, $formBlue, 50%);
$formText: $colText;
$formTextDisabled: mix($colLinesGrey, $colText);
$formTextError: #cc9955;
$formTextLight: #737373;
$formWidth: 720px;

$mediaEnormousWidth: 1600px;
$mediaHugeWidth: 1320px;
$mediaLargeWidth: 1080px;
$mediaSemiLargeWidth: 920px;
$mediaMediumWidth: 760px;
$mediaSmallWidth: 580px;
$mediaTinyWidth: 460px;

//480
//640
//800
//960
//1120
//1280
//1440
//1600

$pageContentMaxWidth: 960px;
$pageContentMaxWidthPlus: 1200px;
$pageContentMaxWidthSuper: 1920px;

$pageGutter: 60px;
$pageGutterBottom: 48px;
$pageGutterLarge: 36px;
$pageGutterMedium: 24px;
$pageGutterSmall: 24px;
$pageGutterTiny: 12px;
$pageGutterTop: 48px;

$size0: $sizeBase / 2;
$size1: $sizeBase * 1;
$size1h: $sizeBase * 1 + $sizeBase / 2;
$size2: $sizeBase * 2;
$size3: $sizeBase * 3;
$size4: $sizeBase * 4;
$size5: $sizeBase * 5;
$size6: $sizeBase * 6;
$size7: $sizeBase * 7;
$size8: $sizeBase * 8;


$gridMargin: $size2;
$gridMarginTight: $size1;
$gridPad: $size1;
$gridPadTight: $size0;


$sizeIconEnormous: 128px;
$sizeIconHuge: 96px;
$sizeIconLarge: 64px;
$sizeIconMedium: 48px;
$sizeIconSmall: 24px;
$sizeIconTiny: 16px;
$sizeListIcon: 16px;
$sizeListIconPad: $size0;
$sizeListIndent: $sizeListIcon + $sizeListIconPad;
$sizeMargin: $size1;
$sizePadding: $size2;

$zIndex: 50;
$zIndexLightbox: 300;
$zIndexOverlayHeader: 400;
$zIndexTouchHints: 500;
//$sizeIconBigIndent: $pageContentMaxWidth / 12

/* common mix-ins */

@mixin clear() {
	&:after {
		clear: both;
		content: " ";
		display: block;
		height: 0px;
		overflow: hidden; } }

@mixin clearLeft() {
	&:after {
		clear: left;
		content: " ";
		display: block;
		height: 0px;
		overflow: hidden; } }

@mixin childNoMargins() {
	> *:first-child {
		margin-top: 0px; }
	> *:last-child {
		margin-bottom: 0px; } }

@mixin link($normal: 1, $focus: 0, $textColour: inherit) {
	@if $normal == 0 {
		text-decoration: none; }
	@else {
		text-decoration: underline; }
	&:link {
		@if $normal == 0 {
			text-decoration: none; }
		@else {
			text-decoration: underline; } }
	&:hover,
	&:focus {
		@if $focus == 0 {
			text-decoration: none; }
		@else {
			text-decoration: underline; } }
	@if $textColour != inherit {
		color: $textColour;
		&:link,
		&:hover,
		&:focus,
		&:visited {
			color: $textColour; } }
	&:link {
		@content; } }

@mixin caption($icon: $fa-var-camera, $iconColour: inherit) {
	color: $colTextGrey;
	font-size: $fontSize - 2;
	line-height: $fontSize + 2;
	margin-top: 2px;
	&:before {
		content: $icon;
		font-family: $fontFA;
		display: inline-block;
		margin-right: $size0;
		color: $iconColour; } }

@mixin truncate() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; }

@mixin gw($cols) {
	//12 grid width
	width: 100% / 12 * $cols; }

@mixin iconBefore($content, $colour: inherit, $width: 0, $fontFamily: "FontAwesome") {  //to be retired
	@if $width > 0 {
		position: relative;
		padding-left: $sizeListIndent; }
	&:before {
		color: $colour;
		content: $content;
		display: inline-block;
		font-family: $fontFamily;
		text-decoration: none;
		text-align: center;
		@if $width > 0 {
			position: absolute;
			left: 0px;
			width: $width * 0.75; }
		@if $width == 0 {
			width: $sizeListIcon;
			padding-right: $sizeListIndent - $sizeListIcon; }
		@content; } }

@mixin iconBeforeFixed($content, $colour, $iconSize: $sizeListIcon, $fontFamily: "FontAwesome") {
	position: relative;
	padding-left: $iconSize + $sizeListIconPad;
	&:before {
		color: $colour;
		content: $content;
		display: inline-block;
		font-family: $fontFamily;
		text-decoration: none;
		text-align: center;
		width: $iconSize;
		position: absolute;
		left: 0px;
		@content; } }

@mixin iconAfterFixed($content, $colour: inherit, $iconSize: $sizeListIcon, $fontFamily: "FontAwesome") {
	position: relative;
	padding-right: $iconSize + $sizeListIconPad;
	&:after {
		color: $colour;
		content: $content;
		display: inline-block;
		font-family: $fontFamily;
		text-decoration: none;
		text-align: center;
		width: $iconSize;
		position: absolute;
		right: 0px;
		@content; } }

@mixin iconAfter($content, $colour: inherit, $width: 0, $fontFamily: "FontAwesome") {  //to be retired
	@if $width > 0 {
		position: relative;
		padding-right: $sizeListIndent; }
	&:after {
		color: $colour;
		content: $content;
		display: inline-block;
		font-family: $fontFamily;
		text-decoration: none;
		text-align: center;
		width: $sizeListIcon;
		@if $width > 0 {
			position: absolute;
			right: 0px;
			width: $width * 0.75; }
		@if $width == 0 {
			padding-left: $sizeListIndent - $sizeListIcon; }
		@content; } }

//full width 1080 = 60 + 700 + 40 + 220 + 60
@mixin mediaFull() {
	@media screen and (min-width: 1080px) {
		@content; } }

//tablets 760 = 60 + 640 + 60
@mixin mediaHuge() {
	@media screen and (max-width: $mediaHugeWidth - 1) {
		@content; } }

//tablets 760 = 60 + 640 + 60
@mixin mediaLarge() {
	@media screen and (max-width: $mediaLargeWidth - 1) {
		@content; } }

// 580 = 60 + 460 + 60
@mixin mediaMedium() {
	@media screen and (max-width: $mediaMediumWidth - 1) {
		@content; } }

// 460 = 60 + 340 + 60
@mixin mediaSmall() {
	@media screen and (max-width: $mediaSmallWidth - 1) {
		@content; } }

// 320 = 20 + 280 + 20
@mixin mediaTiny() {
	@media screen and (max-width: $mediaTinyWidth - 1) {
		@content; } }

@mixin mediaMinEnormous() {
	@media screen and (min-width: $mediaEnormousWidth) {
		@content; } }

@mixin mediaMinHuge() {
	@media screen and (min-width: $mediaHugeWidth) {
		@content; } }

@mixin mediaMinLarge() {
	@media screen and (min-width: $mediaLargeWidth) {
		@content; } }

@mixin mediaMinMedium() {
	@media screen and (min-width: $mediaMediumWidth) {
		@content; } }

@mixin mediaMinSmall() {
	@media screen and (min-width: $mediaSmallWidth) {
		@content; } }

// dpi is ratio*96
@mixin highDpi() {
	@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
		@content; } }

// dpi is ratio*96
@mixin lowDpi() {
	@media (-webkit-max-device-pixel-ratio: 1.24), (max-resolution: 119dpi) {
		@content; } }



@mixin arrowBelow($width, $height, $colour) {
	position: relative;
	&:after {
		top: 100%;
		left: 50%;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		margin-left: 0 - $width / 2;
		border-style: solid;
		border-width: $height ($width / 2) 0 ($width / 2);
		border-color: $colour transparent transparent transparent;
		background: transparent;
		@content; } }

@mixin arrowRight($width, $height, $colour) {
	position: relative;
	&:after {
		left: 100%;
		top: 50%;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		margin-top: 0 - $height / 2;
		border-style: solid;
		border-width: ($height / 2) 0 ($height / 2) $width;
		border-color: transparent transparent transparent $colour;
		background: transparent;
		@content; } }

@mixin arrowLeft($width, $height, $colour) {
	position: relative;
	&:after {
		right: 100%;
		top: 50%;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		margin-top: 0 - $height / 2;
		border-style: solid;
		border-width: ($height / 2) $width  ($height / 2) 0;
		border-color: transparent $colour transparent transparent;
		background: transparent;
		@content; } }

@mixin arrowAbove($width, $height, $colour) {
	position: relative;
	&:after {
		bottom: 100%;
		left: 50%;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		margin-left: 0 - $height / 2;
		border-style: solid;
		border-width: 0 ($width / 2) $height ($width / 2);
		border-color: transparent transparent $colour transparent;
		background: transparent;
		@content; } }



//=flexTable()
//	display: table //fallback
//	display: flex //override table cell in compatable browsers
//	align-items: stretch
//	align-content: stretch
//
//=flexCell()
//	display: table-cell //fallback
//	//display: flex //override table cell in compatable browsers
//	margin: 0
//	flex-basis: 0
//	flex-grow: 1

@mixin verticalCenterGhost($fontResetSize: $fontSize) {
	text-align: center;
	white-space: nowrap;
	font-size: 0;
	&:before { //ghost element because IE11 glitches with table cell
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%; }
	> * {
		white-space: normal;
		font-size: $fontResetSize;
		display: inline-block;
		vertical-align: middle; } }




@keyframes uwacore-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg); }
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg); } }

@mixin psuedoLoadAnim() {
	content: $fc-var-core-loading-circle;
	font-family: $fontCustom;
	animation: uwacore-spin 4s linear 0s infinite;
	padding: 0px;
	margin: 0 0 0 $size0;
	box-sizing: border-box;
	text-align: center;
	width: 1em;
	line-height: 1;
	font-size: 1em;
	-webkit-filter: blur(0);
	filter: blur(0); }


@mixin psuedoLoadAnimCentred() {
	@include psuedoLoadAnim;
	position: absolute;
	display: inline-block;
	text-align: center;
	left: 50%;
	top: 50%;
	margin-left: -0.5em;
	margin-top: -0.5em;
	color: $colTextGrey; }

@mixin loadMessage($message) {
	&:before {
		@include psuedoLoadAnimCentred;
		font-size: 20px; }
	&:after {
		display: block;
		text-align: center;
		position: absolute;
		top: 50%;
		margin-top: $size2;
		left: 0;
		width: 100%;
		font-size: $fontSize;
		color: $colTextGrey;
		content: $message; } }

@mixin flexBox($align: center) {
	/* If flexbox not working see https://github.com/philipwalton/flexbugs */
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-flow: row wrap;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: $align;
	align-content: stretch;
	align-items: stretch; }


@mixin flexItem($values: 0 1 auto) {
	// grow shrink bias
	-webkit-box-flex: $values;
	-moz-box-flex:  $values;
	-webkit-flex:  $values;
	-ms-flex:  $values;
	flex:  $values;
	flex-height: stretch;
	-ms-flex-align: stretch; }


@mixin flexOrder($val) {
	-webkit-box-ordinal-group: $val;
	-moz-box-ordinal-group: $val;
	-ms-flex-order: $val;
	-webkit-order: $val;
	order: $val; }

@mixin flexNone() {
	// iOS 8 needs the important
	display: block !important;
	> * {
		-webkit-box-flex: none;
		-moz-box-flex: none;
		-webkit-flex: none;
		-ms-flex: none;
		flex: none;
		display: block; } }


@mixin fluidText($sizeMin, $sizeMax) {
	$offset: $sizeMin / $sizeFontRoot;
	$range:  ($sizeMax / $sizeFontRoot) - ($sizeMin / $sizeFontRoot);

	font-size: $sizeMin / $sizeFontRoot * 1rem;
	@media (min-width: 40rem) { // 640px
		font-size: calc(#{$offset}rem + #{$range} * ((100vw - 40rem)/40)); }
	@media (min-width: 80rem) { // 1280px
		font-size: $sizeMax / $sizeFontRoot * 1rem; } }

@mixin fluidTextOld($sizeMin, $sizeMax) {
	$offset: $sizeMin / $sizeFontRoot;
	$range:  ($sizeMax / $sizeFontRoot) - ($sizeMin / $sizeFontRoot);
	font-size: $sizeMin / $sizeFontRoot * 1rem;
	@media (min-width: 40rem) { // 640px
		font-size: calc(#{$offset}rem + #{$range} * ((100vw - 40rem)/20)); }
	@media (min-width: 60rem) { // 1280px
		font-size: $sizeMax / $sizeFontRoot * 1rem; } }

@mixin fluidSidePadding($sizeMin, $sizeMax) {
	$offset: $sizeMin / $sizeFontRoot;
	$range:  ($sizeMax / $sizeFontRoot) - ($sizeMin / $sizeFontRoot);
	padding-left: $sizeMin / $sizeFontRoot * 1rem;
	padding-right: $sizeMin / $sizeFontRoot * 1rem;
	@media (min-width: 40rem) { // 640px
		padding-left: calc(#{$offset}rem + #{$range} * ((100vw - 40rem)/20));
		padding-right: calc(#{$offset}rem + #{$range} * ((100vw - 40rem)/20)); }
	@media (min-width: 60rem) { // 1280px
		padding-left: $sizeMax / $sizeFontRoot * 1rem;
		padding-right: $sizeMax / $sizeFontRoot * 1rem; } }


@mixin fluidPageContainer() {

	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	box-sizing: border-box;
	//border: 1px dotted red
	padding-left: $size2;
	padding-right: $size2;
	> * {
		width: $pageContentMaxWidth;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		position: relative; }
	@media (max-width: $pageContentMaxWidth + $pageGutterSmall * 2) {
		width: 100%;
		padding-left: calc(12px + 12 * (100vw - 480px) / 480);
		padding-right: calc(12px + 12 * (100vw - 480px) / 480);
		> * {
			width: 100%; } }
	@media (max-width: 480px) {
		padding-left: $pageGutterTiny;
		padding-right: $pageGutterTiny; } }


@mixin fluidPageContainerWide() {

	> * {
		width: $pageContentMaxWidthPlus + $pageGutterSmall * 2; }
	@media (max-width: $pageContentMaxWidthPlus + $pageGutterSmall * 2) {
		width: 100%;
		> * {
			width: 100%; } } }

