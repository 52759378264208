/* lists and icons - Written by Timothy Phillips */

@import "_variables.sass";

@import "_fontcustom-vars.scss";


/* Simple list styles */

ul, ol, dl {
	margin: 0 0 $size1 0;
	list-style-position: outside; }

ul {
	/* Defaults for unordered lists */
	list-style: none;// +iconBefore leaves too much junk to override
	// *list-style-image: url(../img/symbol/bullet-default-ie.png)
	//margin-left: $sizeListIndent
	> li {
		margin: 0px 0px $size0 0;
		padding-left: $sizeListIndent;
		background: url(../img/symbol/bullet-default.svg) 1px 3px no-repeat;
		//background: url(../img/symbol/bullet-default-new.svg) 2px 7px no-repeat


		@include childNoMargins; }
	&.list-padding > li {
		margin-bottom: $size1; }
	> li:last-child {
		margin-bottom: 0px; }



	&.ruled li {
		border-bottom: 1px dotted $colLinesGrey;
		padding-bottom: $size1;
		margin-bottom: $size1;
		&:last-child {
			border-bottom: none;
			padding-bottom: 0; } }
	&.compact li {
		line-height: 1.15em; } }


li > ul, li > ol {
	margin-top: $size1; }

ul.links, ul.linklist, div.site-map ul {
	@include childNoMargins;
	> li {
		list-style: none;
		background: none;
		margin-left: 0px;
		@include iconBeforeFixed($fa-var-angle-right, $colGold);
 } }		//+iconBeforeFixed($fc-var-core-bullet, $colGold, $sizeListIcon, $fontCustom)
li.link {
	list-style: none;
	background: none;
	margin-left: 0px;
	@include iconBeforeFixed($fa-var-angle-right, $colGold); }

/* ul.columns - Columns ordered down then right, rather than the usuial right then down.
 * Browser support may be a little hit and miss */

ul.columns, ul.columns-3, ul.columns-4 {
	// +clear
	// margin-left: -$size1 !important
	// margin-right: -$size1 !important
	//> li
	// 	float: left
	// 	width: 50%
	//	padding: 0px $size1 0px ($sizeListIndent + $size1)
	// 	background-position: (1px + $size1) 3px
	// 	&:before
	// 		left: $size1
	-moz-column-count: 2;
	-moz-column-gap: $size2;
	-webkit-column-count: 2;
	-webkit-column-gap: $size2;
	column-count: 2;
	column-gap: $size2;
	&.columns-3 {
		-moz-column-count: 3;
		-webkit-column-count: 3;
		column-count: 3; }
	&.columns-4 {
		-moz-column-count: 4;
		-webkit-column-count: 4;
		column-count: 4; } }

/* override defaults with ul.no-bullet and ul.no-numbers */

ul.no-bullet {
	list-style: none;
	margin-left: 0;
	padding: 0;
	background-image: none;
	> li {
		margin-left: 0;
		padding-left: 0px;
		background-image: none; }
	&.columns > li {
		padding: 0px $size1; } }

ol.no-numbers {
	list-style: none;
	padding: 0px;
	> li {
		padding: 0px; } }

/* ul.inline and other varients for using lists as simple layouts without bullets */

ul.inline {
	list-style-image: none;
	margin-bottom: $size1;
	margin-right: -$size1;
	@include clear;
	> li {
		margin: 0 $size1 $size1 0;
		display: inline-block;
		> *:last-child {
			margin-bottom: 0px; } } }

ul.inline-links {
	list-style-image: none;
	margin: 0 (-$size0) $size0 (-$size0);
	@include clear;
	> li {
		list-style: none;
		background: none;
		margin: 0 0 $size0 0;
		padding: 0 $size0;
		display: block;
		float: left;
		vertical-align: bottom;
		img {
			vertical-align: bottom; }
		&:after {
			content: "|";
			display: block;
			padding: 0 0 0 $size1;
			display: inline;
			color: $colLinesGrey; }
		&:last-child:after {
			display: none; }
		&.no-divider:after {
			content: ""; } } }


ul.inline-center {
	font-size: 0px;
	margin: $size2 (-$size1) $size2 (-$size1);
	margin-bottom: -$size2 !important;
	text-align: center;
	list-style: none;
	&.width-large > li {
		width: 220px; }
	&.width-medium > li {
		width: 180px; }
	&.width-small > li {
		width: 120px; }
	&.width-tiny > li {
		width: 80px; }

	> li {
		margin: 0px;
		display: inline-block;
		font-size: $fontSize;
		vertical-align: middle;
		background-image: none;
		padding: 0px $size1 $size2 $size1; }
	&.align-top > li {
		vertical-align: top; } }

/* Ordered lists */

ol {
	list-style-position: outside;
	padding-left: $sizeListIndent - 5px;
	> li {
		padding-left: 5px;
		margin: 0px 0px $size0 0;
		> ol {
			margin-bottom: 0px; } }

	&.alpha, &[type="a"] {
		counter-reset: listAlpha;
		list-style-type: none;

		> li {
			position: relative;
			counter-increment: listAlpha;
			&:before {
				right: 100%;
				position: absolute;
				content: counter(listAlpha, lower-alpha) ")"; } } }
	&.roman, &[type="i"] {
		list-style-type: lower-roman; } }
//	&.nested
//		counter-reset: counter-ol
//		list-style-type: none
//		padding-left: $sizeListIndent
//		position: relative
//		margin: 0px 0px $size1 0px
//		li:before
//			position: absolute
//			counter-increment: counter-ol
//			left: 3px
//			content: counters(counter-ol, ".") "."
//			font-weight: 300
//		ol
//			counter-reset: counter-ol
//			list-style-type: none
//			padding-left: $sizeListIndent * 1.5
//			margin: 0px
//			ol
//				padding-left: $sizeListIndent * 2

/* ol.index and table of contents */
ol.toc, ol.index {
	list-style: none;
	padding-left: 0px;
	counter-reset: index;
	margin: $size2 0;
	a {
		text-decoration: none;
		&:hover {
			text-decoration: underline; } }
	> li {
		border-bottom: 1px solid $colLinesGrey;
		margin-left: 0px;
		margin-bottom: 0px;
		padding-top: $size0;
		padding-bottom: $size0;
		padding-left: $size2 + $size0;
		counter-increment: index;
		position: relative;
		&:before {
			content: counter(index) '.';
			position: absolute;
			display: block;
			left: 0px;
			width: $size2;
			text-align: right; }
		&:last-child {
			border-bottom: 0px; }
		> ol, > ul {
			margin-left: $size1; } }
	// &.split-column
	// 	> .second-half
	// 		display: none;
	// 		+mediaMedium
	// 			display: block;
	// &.split-column-second
	// 	+mediaMedium
 }	// 		display: none



/* definition lists */
dl {
	margin-bottom: $size1;
	> dt {
		font-size: inherit;
		margin-bottom: 0; //$size0
		text-align: left;
		> *:first-child {
			margin-top: 0px; } }
	&:not(.no-bold-titles) > dt {
		font-weight: 600;
 }		// Rory said 700 but I can't STAND that.
	> dd {
		margin-bottom: $size1;
		text-align: left;
		@include childNoMargins; }



	&.list {
		> dt {
			margin: 0 0 $size0 0;
			@include iconBeforeFixed($fc-var-core-bullet, $colTextLight, $sizeListIcon, $fontCustom) {
				font-weight: normal; } }
		> dd {
			margin: 0 0 $size1 $sizeListIndent; } }

	&.linklist,
	&.links {
		> dt {
			margin: 0 0 $size0 0;
			//font-weight: bold
			@include iconBeforeFixed($fa-var-angle-right, $colGold) {
				font-weight: normal; } }
		> dd {
			margin: 0 0 $size1 $sizeListIndent; } }
	&.list, &.links {
		> dt small {
			float: right;
			margin-left: 1em;
			line-height: inherit; } }

	&[class^="columns-"], &[class*="columns-"], &.columns {
		// this clear was missing.... why?
		@include clear;
		> dt {
			clear: left;
			float: left;
			margin: 0 $size1 0 0;
			width: 200px;
			word-wrap: break-word; }
		> dd {
			margin-left: 200px;
			padding-left: $size1;
			margin-bottom: $size1;
			//float: left
			@include clear;
			@include childNoMargins;
			&:last-child {
				margin-bottom: 0px; } }

		@include mediaSmall {
			&:not(.columns-mobile) {
				> dt {
					width: 100% !important; }
				> dd {
					clear: left;
					margin-left: $size1 !important; } } } }

	&.columns-quarter {
		> dt {
			width: 25%; }
		> dd {
			margin-left: 25%; } }
	&.columns-third {
		> dt {
			width: 33.3333%; }
		> dd {
			margin-left: 33.3333%; } }
	&.columns-half {
		> dt {
			width: 50%; }
		> dd {
			margin-left: 50%; } }
	&.columns-tiny {
		> dt {
			width: 80px; }
		> dd {
			margin-left: 80px; } }
	&.columns-small {
		> dt {
			width: 120px; }
		> dd {
			margin-left: 120px; } }
	&.columns-medium {
		> dt {
			width: 200px; }
		> dd {
			margin-left: 200px; } }

	&.ruled.columns {
		position: relative;
		> dd + dt:before {
			position: absolute;
			display: block;
			content: '';
			height: 1px;
			width: 100%;
			margin-top: 0 - $size1;
			border-top: 1px dotted $colLinesGrey; }

		> dd + dt, &.columns > dd + dt + dd {
			padding-top: $size1 + 1px; } }
	&.ruled:not(.columns) {
		> dd + dt {
			border-top: 1px dotted $colLinesGrey;
			padding-top: $size1;

			//	border-top: 1px dotted $colLinesGrey
			// &.columns > dd
			// 	+clear
			// 	margin-bottom: $size1 //better align image with text
			// + dl.ruled
			// 	border-top: 1px dotted $colLinesGrey
			// 	padding-top: $size1
 } } }			// 	margin-top: -$size1
/* dl.inline for a continuous compact list - good for tags and properties in search results */

dl.inline {
	display: block;
	margin: $size1 0;
	dt, dd {
		display: inline-block;
		margin-right: $size0;
		margin-bottom: $size0; }
	dd + dt {
		&:before {
			content: "|";
			display: inline-block;
			margin-right: $size0;
			color: $colTextGrey; } }


	ul {
		margin: 0px;
		display: inline;
		li {
			display: inline-block;
			margin: 0px;
			+ li {
				margin-left: $size0; } } } }




/* icons */

ul.icons {
	/* for font-awesome and symilar */
	list-style: none;
	margin-left: 0;
	padding: 0;
	> li {
		margin-left: 0;
		padding-left: $sizeListIndent;
		position: relative;
		background-image: none;
		min-height: $fontLineHeight;
		> a {
			display: inline-block; }
		i.fa, &[class^="fa-"]:before, &[class*=" fa-"]:before {
			left: 0em;
			line-height: inherit;
			position: absolute;
			text-align: center;
			font-family: $fontFA;
			width: 1em;
			display: inline-block;
			color: $colHeader; } } }

dl.icons {
	//margin-left: 30px
	> dt, > dd {
		padding-left: $sizeListIndent; }
	> dt {
		position: relative;
		i.fa, &[class^="fa-"]:before {
			left: 0em;
			line-height: inherit;
			position: absolute;
			text-align: center;
			font-family: $fontFA;
			font-size: $fontSize;
			width: $sizeListIcon;
			font-weight: normal;
			display: inline-block;
			color: $colHeader; } } }


.icon-tiny, .icon-small, .icon-medium, .icon-large, .icon-huge,
.icons-tiny > *, .icons-small > *, .icons-medium > *, .icons-large > *, .icons-huge > * {
	position: relative;
	clear: left;
	background-image: none !important;
	> .icon, &:before {
		float: left;
 }		//margin-bottom: $size0 // what was this for?
	&:before {
		display: inline-block;
		color: $colHeader;
		font-family: $fontFA;
		font-weight: normal;
		text-align: center; } }

div.content {
	// div.content needed to override negative margins on ul.grid-x-col
	.icons-tiny, .icons-small, .icons-medium, .icons-large, .icons-huge {
		@include clear;
		margin-left: 0px;
		margin-right: 0px; } }



@each $name, $size, $pad in (tiny, $sizeIconTiny, $size0), (small, $sizeIconSmall, $size1), (medium, $sizeIconMedium, $size1), (large, $sizeIconLarge, $size1 + $size0), (huge, $sizeIconHuge, $size2) {
	.icon-#{$name},
	.icons-#{$name} > * {
		padding-left: $size + $pad  !important;
		min-height: $size;
		> .icon, &:before {
			max-width: $size;
			max-height: $size;
			margin-left: 0 - ($size + $pad) !important; }
		&:before {
			font-size: $size;
			line-height: max($size, $fontLineHeight);
			width: $size;
			height: $size; }
		&.icon-above {
			padding-top: $size + $pad !important;
			padding-left: 0 !important;
			> .icon, &:before {
				margin-left: 0 - ($size / 2) !important;
				left: 50%;
				top: 0; } } }



	dl.icons-#{$name} > dd {
		min-height: $fontLineHeight; }
	a.icon-#{$name}, span.icon-#{$name} {
		display: inline-block; } }


.icon-above {
	margin-top: $size1;
	> .icon, &:before {
		position: absolute;
		padding-top: 12px; } }

.icons-vertical-middle {
	> * {
		padding-top: $size2;
		padding-bottom: $size2;
		> .icon, &:before {
			position: absolute;
			float: none;
			top: 50%; } }
	@each $name, $size in (tiny, $sizeIconTiny), (small, $sizeIconSmall), (medium, $sizeIconMedium), (large, $sizeIconLarge), (huge, $sizeIconHuge) {
		&.icons-#{$name} > * {
			min-height: $size;
			> .icon, &:before {
				margin-top: 0 - ($size / 2); } } } }

ul.icon-circles-above {
	list-style: none;
	font-size: 0px;
	text-align: center;
	margin: $size1 (-$size1) $size1 (-$size1);

	> li {
		background-image: none;
		vertical-align: top;
		padding: ($sizeIconMedium + $size4 + $size0) $size1 0 $size1;
		margin-bottom: $size1;
		position: relative;
		text-transform: uppercase;
		display: inline-block;
		font-size: 16px;
		color: $colBlue;
		width:  $sizeIconMedium + $size8;
		&:before {
			font-family: $fontFA;
			font-size: $sizeIconMedium;
			color: $colBlue;
			line-height: $sizeIconMedium; }
		> .icon, &:before {
			margin-left: -$size2 - ($sizeIconMedium / 2) !important;
			left: 50%;
			top: 0;
			position: absolute;
			box-sizing: content-box;
			padding: $size2 - 2;
			width: $sizeIconMedium;
			height: $sizeIconMedium;
			border: 2px solid $colBlue;
			vertical-align: middle;
			border-radius: 50%; } } }



[class^="icon-"].text-hidden {
	font-size: 0px !important;
	padding: 0px !important;
	text-decoration: none !important;
	.icon, &:before {
		display: block;
		margin: $size1 !important;
		float: none !important; } }
[class^="icon-"].text-hidden {
	&:hover, &:focus {
		border-bottom: 1px solid rgba($colLinesGrey, 0.5); } }


.icon-gold:before {
	color: $colGold !important; }
.icon-grey:before {
	color: $colTextGrey !important; }
.icon-blue:before {
	color: $colBlue !important; }
.icon-white:before {
	color: white !important; }

ul.vertical-middle {
	align-items: flex-center;
	//margin-left: 0px
	//margin-right: 0px
	> li {
		white-space: nowrap;
		vertical-align: middle;
		//align-self: flex-start
		> * {
			vertical-align: middle;
			width: 100%;
			display: inline-block;
			white-space: normal;
			line-height: 1.1em; }
		&:after {
			// ghost element allows vertical centering
			display: inline-block;
			vertical-align: middle;
			height: 100%;
			content: ""; } } }



ul.icons-gold > li, dl.icons-gold > dt {
	> i.fa, &[class^="fa-"]:before, &[class*=" fa-"]:before {
		color: $colGold; } }

ul.icons-grey > li, dl.icons-grey > dt {
	> i.fa, &[class^="fa-"]:before, &[class*=" fa-"]:before {
		color: $colTextGrey; } }

ul.icons-blue > li, dl.icons-blue > dt {
	> i.fa, &[class^="fa-"]:before, &[class*=" fa-"]:before {
		color: $colBlue; } }



dl {
	&.icons-small, &.icons-medium, &.icons-large, &.icons-huge {
		dt {
			clear: left;
			min-height: $size2;
			&:before, img.icon {
				top: $size0;
				margin-top: 0px; } }
		dd {
			clear: none; } }
	&.ruled {
		margin: $size2 0;
		dd + dt {
			&:before, img.icon {
 } }				//top: $size2
		dt {
			&:before, img.icon {
				margin-bottom: $size1; } } } }

dl.icons-gold {
	> dt {
		> i.fa, &[class^="fa-"]:before, &[class*=" fa-"]:before {
			color: $colGold; } } }


a.icon-navigation {
	width: 48px;
	height: 40px;
	text-align: center;
	display: inline-block;
	font-size: 11px;
	line-height: 11px;
	font-weight: 400;
	text-transform: uppercase;
	font-family: $fontSans;
	padding: 0 $size0;
	@include link(0, 1, $colTextGrey);
	&:before {
		font-size: 22px;
		line-height: 22px;
		padding: 2px 0 5px 0;
		display: inline-block;
		font-family: $fontFA; }
	&:active, &:hover {
		&:before {
			text-decoration: none; } } }
h3 a.icon-navigation {
	margin-top: -$size1; }

