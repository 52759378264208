/* matrix specific elements - Written by Timothy Phillips */

@import "_variables.sass";

/* nested files */
div.content .listed_file {
	@include clear;
	clear: left;
	margin-bottom: $size1;
	img {
		float: left;
		max-width: 32px;
		padding-top: 3px; }
	div {
		margin-left: $size3;
		line-height: $fontSize + 2;
		font-size: $fontSize - 2;
		> a {
			display: block; }
		> br {
			display: none; }
		> span {
			color: $colTextGrey;
			white-space: no-wrap;
			float: left;
			margin-right: $size2; } } }


div.content ul.list-assets {
	@include clear;
	li {
		float: left;
		width: 50%;
		min-height: $size3;
		padding-left: $size3;
		line-height: $fontSize + 2;
		font-size: $fontSize - 2;
		margin-bottom: $size1;
		&:nth-child(2n-1) {
			clear: left;
			padding-right: $size1; }
		&:nth-child(2n) {
			padding-left: $size4;
			background-position: $size1 0; }
		> a {
			display: block; }
		> span {
			color: $colTextGrey;
			white-space: no-wrap;
			float: left;
			margin-right: $size2; }
		@include mediaMedium {
			width: 100%;
			&:nth-child(2n-1) {
				padding-right: $size0; }
			&:nth-child(2n) {
				padding-left: $size3;
				background-position: 0 0; } } } }
div.content div.right-col ul.list-assets li {
	width: 100%;
	&:nth-child(2n-1) {
		padding-right: $size0; }
	&:nth-child(2n) {
		padding-left: $size3;
		background-position: 0 0; } }


/* contact directory tweak */


div.cdl-profile { //contact directory loader
	> h3 {
		clear: left; } }

/* div.event-with-icon - ToDo: what's this for?  */
div.event-with-icon {
	padding-left: 60px;
	position: relative;
	margin-bottom: $size1;
	img.event-date-icon {
		position: absolute;
		left: 0 - $sizeListIndent;
		top: 0px; } }
