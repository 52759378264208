/* Footer block - Written by Timothy Phillips */

@import "_variables";


/* social icons */

#uwafooter-site-icons {
	padding-top: 0px !important;
	> * {
		display: none; }
	ul {
		padding-top: $size1;
		display: block;
		list-style: none;
		li {
			background-image: none;
			display: inline-block;
			line-height: $size2;
			padding: 0px;
			margin: 0px 0 $size1 0px;
			font-size: 0px;
			position: relative;
			padding: 0 $size0;
			a {
				padding: 0;
				display: block;
				border-radius: 3px;
				vertical-align: middle;
				img {
					vertical-align: middle; }
				&:hover, &:focus {
					> * {
						margin-bottom: 2px;
						margin-top: -2px; }
					i.fa {
						//color: white !important
						text-shadow: 0 2px 2px rgba(#000, 0.2); }
					img {
						box-shadow: 0 2px 2px rgba(#000, 0.2);
						background-color: rgba(#000, 0.2);
						border-radius: 2px; } } }
			i.fa {
				width: $size2;
				font-size: $size2;
				text-align: center;
				vertical-align: middle;

				// &.fa-twitter
				// 	color: $colTwitter
				// &.fa-facebook
				// 	color: $colFacebook
				// &.fa-linkedin
				// 	color: $colLinkedIn
				// &.fa-instagram
				// 	color: $colInstagram
				// &.fa-flickr
				// 	color: $colFlickr
				// &.fa-youtube-play
				// 	color: $colYoutube
				// &.fa-weibo
				// 	color: #D72928
				// &.fa-wexin
 } } } }				// 	color: #78AB34

/* #footercontainer */
#footercontainer {
	font-size: 14px;
	line-height: 18px;
	color: #2b2b2b;
	clear: both;
	> div {
		@include fluidPageContainer;
		// box-sizing: border-box
		// padding: 0px $pageGutter
		// +mediaLarge
		// 	padding: 0px $pageGutterLarge
		// +mediaMedium
		// 	padding: 0px $pageGutterMedium
		// +mediaSmall
		// 	padding: 0px $pageGutterSmall
		// +mediaTiny
		// 	padding: 0px $pageGutterTiny
		// > div
		// 	width: $pageContentMaxWidth
		// 	margin: 0px auto
		// 	box-sizing: border-box
		// 	+mediaLarge()
 }		// 		width: 100%

	#uwafooter-links {
		background: url("../img/bgs/footer-watermark-refresh.png") 50% -15px no-repeat #EBEBEB;
		@include mediaLarge() {
			width: 100%; }
		> div {
			@include clear;
			padding: $size2 0 $size1 0;

			> div {
				margin: 0 (-$size0);
				font-size: 0px;
				> div {
					font-size: 14px;
					display: inline-block;
					vertical-align: top;
					box-sizing: border-box;
					margin-bottom: $size1;
					padding: 0px $size0;
					width: 25%;
					@include mediaLarge {
						width: 50%;
						margin-bottom: $size2; }
					@include mediaSmall {
						width: 100%;
						display: block; } }
				&.primary-links {
					> div:last-child h1 {
						// few px short of fitting on one line
						margin-right: -$size0; } }
				&.bottom-links {
					> div {
						vertical-align: bottom; }
					.logo {
						width: 25%;
						img {
							max-width: 100%;
							vertical-align: baseline; } }
					.cricos {
						width: 20%;
						@include mediaLarge {
							width: 25%; } }
					.logo, .cricos {
						@include mediaSmall {
							width: 50%;
							display: inline-block; } }
					.other-links {
						width: 55%;
						ul {
							float: right;
							list-style-image: none;
							margin: 0 (-$size0) 0 0;
							@include clear;
							> li {
								list-style: none;
								background: none;
								margin: 0;
								padding: 0 $size0 0 0;
								display: block;
								float: left;
								&:after {
									content: "|";
									display: block;
									padding: 0 0 0 $size0;
									display: inline; }
								&:last-child:after {
									display: none; } } }
						@include mediaLarge {
							width: 50%;
							ul {
								width: 66.6666%;
								float: none;
								li {
									display: block;
									float: none;
									padding: 0px;
									margin-top: $size0;
									&:after {
										display: none; } } } }
						@include mediaSmall {
							width: 100%; } } } } }



		ul {
			margin: 0px; }
		ul.icons > li > i {
			color: $colText; }
		ul.links {
			> li {
				padding-left: $size1;
				&:before {
					text-align: left; } } }

		h1 {
			font-size: 16px;
			line-height: 20px;
			color: #2b2b2b;
			font-family: $fontUWA;
			margin-bottom: $sizeMargin;
			margin-top: 0px;
			text-align: left;
			a {
				@include link(0,1); } }
		a {
			color: $colText; }

		a[href^="tel"] {
			text-decoration: none; }
		a.button {
			min-width: 100%; }

		#uwafooter-subsite {
			display: none;
			&.show {
				display: inline-block; } }
		#uwafooter-site-icons {
			width: 75%; }

		#uwafooter-campaign {
			width: 25%; }


		#uwafooter-site-icons, #uwafooter-campaign {
			@include mediaLarge {
				width: 50%; }
			@include mediaSmall {
				width: 100%; } } }


	#uwafooter-thispage {
		display: none;
		margin-top: -$size1;
		background-color: $colBaseGreyDarker;
		padding-top: $size2;
		padding-bottom: $size2;
		@include mediaLarge {
			margin-top: -$size2; }
		> * {
			display: block;
			dl {
				margin: 0px;
				dt {
					font-size: 14px; }
				dd {
					margin-bottom: $size0;
					&:last-child {
						margin-bottom: 0px; } } } } }
	&.show-thispage {
		#uwafooter-thispage {
			display: block; }
		#uwafooter-thispage-link {
			display: block;

			> a {
				position: relative;
				display: inline-block;
				span {
					position: relative;
					z-index: $zIndex + 10; }
				&:before {
					z-index: $zIndex - 10;
					background-color: $colBaseGreyDarker;
					display: block;
					position: absolute;
					content: "";
					top: -$size0 / 2;
					left: -$size0;
					right: -$size0;
					height: 40px; } } } }

	#uwafooter-brand {
		background-color: $colBlue;
		border-bottom: 4px solid $colGold;
		padding-top: $sizePadding;
		padding-bottom: $sizePadding;
		> div {
			min-height: 55px; }
		a {
			vertical-align: top;
			display: inline-block; }
		.tagline {
			font-family: $fontUWA;
			padding-top: 30px;
			line-height: 25px;
			font-size: 22px;
			@include link(0,1, white);
			@include mediaSmall {
				float: none;
				display: block;
				text-align: center;
				padding-top: 0;
				padding-bottom: $size2;
 } }				//margin: $margin 0 $margin2 0
		.uwacrest {
			@include clear;
			float: right;
			@include mediaSmall {
				float: none;
				text-align: center;
				display: block;
				margin: 0px auto; } } } }

/* body class overrides */

body.footer-no-campaign #footercontainer #uwafooter-campaign {
	display: none; }

body.page-wide, body.style-version-two {
	#footercontainer {
		> div {
			@include fluidPageContainerWide; }
		// > div > div
		// 	width: $pageContentMaxWidthPlus
		// 	+mediaHuge()
 } }		// 		width: 100%


body.footer-hide {
	#footercontainer {
		display: none; } }
