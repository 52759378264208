/* Forms - Written by Timothy Phillips
 * Common light-weight form elements to save loading the full form device */

/* form.combo-group - Cluster of adjacent form elements for a search box
 * In development for new handbooks. Todo: Review */
form.combo-group {
	@include flexBox;
	input, button, h4 {
		white-space: nowrap; }
	input, button {
		border-width: 2px 0px;
		border-style: solid;
		border-radius: 0px;
		height: $formInputHeight;
		line-height: $formLineHeight;
		&:first-child {
			border-left-width: 2px; }
		&:last-child {
			border-right-width: 2px; } }
	h4:first-child {
		margin: 0px;
		height: $formInputHeight;
		line-height: $formInputHeight;
		padding-right: $size2;
		vertical-align: middle;
		+ input {
			border-left-width: 2px; }
		@include mediaMedium {
			width: 100%; } }
	input {
		@include flexItem(1 1 $formLineHeight);
		background: white;
		border-color: $formLines;
		box-sizing: border-box;
		display: block;
		font-family: $fontSans;
		font-size: $formFontSize;

		padding: 1px 8px;
		width: 100%; }
	button {
		@include flexItem(0 0 $formFauxButtonWidth);
		cursor: pointer;
		text-align: center;
		&:not([type="reset"]) {
			border: none;
			background: $formGold;
			border-color: $formGold;
			&:hover, &:focus {
				background: $formGoldLight;
				border-color: $formGoldLight; } }
		&[type="reset"], &.search, {
			&:before {
				font-size: $fontSize;
				display: inline-block;
				vertical-align: middle; } }
		&[type="reset"] {
			font-size: 0px;
			background: white;
			border-color: $formLines;
			color: $formLines;
			&:hover, &:focus {
				background: $formBase; }
			&:before {
				font-family: $fontCustom;
				content: $fc-var-cross; } }
		&.search {
			@include iconAfter($fc-var-core-button-arrows, inherit, 0, $fontCustom);
			transition: margin $animSpeedHover ease;
			font-weight: normal;
			padding-left: $size2;
			padding-right: $size2;
			@include mediaSmall {
				padding-left: $size0;
				padding-right: $size0; } } }
	&.submitted button.search:after {
		@include psuedoLoadAnim;
		// color: $formGold
		// &:before
		// 	color: $formText
		// 	position: absolute
		// 	display: block
		// 	font-family: $fontFA
		// 	top: 50%
		// 	left: 50%
		// 	margin-top: -$size1
		// 	margin-left: -$size1
		// 	content: $fa-var-cog
		// 	animation: uwacore-spin 4s linear 0s infinite
		// 	text-rendering: auto
		// 	//border: 1px solid red
		// 	border-radius: 50%
		// 	width: $size2
		// 	height: $size2
		// 	line-height: $size2
		// 	box-sizing: border-box
 }		// 	vertical-align: middle

	&:hover {
		input, button[type="reset"] {
			border-color: $formLinesHover; } }
	&:focus {
		input, button[type="reset"] {
			border-color: $formBlueLight; } } }


/* div.form - Veeery basic styled form elements with no layout */
div.form {
	input, button {
		border-width: 2px;
		border-style: solid;
		height: $formInputHeight;
		line-height: $formLineHeight; }
	input {
		background-color: white;
		border-color: $formLines;
		box-sizing: border-box;
		//display: block
		font-family: $fontSans;
		font-size: $formFontSize;
		padding: 1px 8px;
		&:hover, &:focus {
			border-color: $formBlueLight; } }
	button, input[type="submit"], input[type="reset"], input[type="button"] {
		cursor: pointer;
		text-align: center;
		border: none;
		background-color: $formGold;
		border-color: $formGold;
		&:hover, &:focus {
			background-color: $formGoldLight;
			border-color: $formGoldLight; }
		&:disabled {
			background-color: $formBaseDisabled;
			color: $formBaseDisabledText; } }
	button[type="submit"], input[type="submit"] {
		@include iconAfter($fc-var-core-button-arrows, inherit, 0, $fontCustom); } }

