/* Images - Written by Timothy Phillips */

@import "_variables.sass";


/* common classes - mostly slignment */

div.content {
	img.right {
		float: right;
		margin: 0 0 $size1 0;
		padding-left: $size2; }
	img.left {
		float: left;
		margin: 0 0 $size1 0;
		padding-right: $size2; }
	img.right, img.left {
		max-width: calc(33.3333% + #{$size1});
		//background-color: white // hides h3 ruler
		@include mediaMedium {
			max-width: 40%;
			max-height: 30vh; } }
	img.crop-circle {
		border-radius: 50%; }
	// img.border
	// 	border: 1px solid $colLinesGrey
 }	// 	padding: 3px

div.image-right, div[class^="image-right-"] {
	float: right;
	max-width: 33.3333%;
	padding-left: $size2;
	padding-bottom: $size1;
	&.image-right-small {
		max-width: 165px + $size2; }
	@include mediaTiny {
		max-width: 50% !important;
		img {
			max-height: 30vh; } } }


p.caption, div.image-right img + p {
	@include caption($fa-var-camera); }

/* fill and crop - TODO: is this used? was there safari compatability issues? */

div.image-fill-and-crop {
	background-size: cover;
	background-position: 50% 20%;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	min-height: 100px;
	> * {
		display: none; }
	@include mediaMedium {
		background-position: -1000px -1000px;
		> img {
			display: block;
			margin: 0px auto;
			max-height: 200px; } } }

/* div.banner - old rebrand styles with uwa curve and optional text - doesn't work well on larger page widths */


div.banner {
	position: relative;
	display: block;
	margin-bottom: $size3;
	margin-left: auto;
	margin-right: auto;
	max-width: 960px;
	z-index: $zIndex;
	img {
		max-width: 100%;
		max-height: 400px;
		vertical-align: middle;
		position: relative; }

	div.overlay {
		//background-color: $colBaseGrey
		text-align: center;
		font-size: 18px;
		padding: $size1;
		position: relative;
		p {
			font-weight: 500; }
		h3 {
			font-size: 38px;
			line-height: 44px;
			margin: 0px auto $size1 auto;
			border: none;
			padding: 0px; }
		*:last-child {
			margin-bottom: 0px; }
		&:after {
			display: none; } }

	@include mediaMinMedium {
		font-size: 0px;
		white-space: nowrap;
		div.overlay {
			display: inline-block;
			vertical-align: middle;
			width: 100%;
			position: static;
			margin-left: -100%;
			&.wide {
				> * {
					max-width: $pageContentMaxWidth * 0.9; } }
			&:after {
				display: none; }
			&:not(.no-underlay):before {
				display: block;
				content: "";
				position: absolute;
				top: 0px;
				left: 0px;
				right: 0px;
				bottom: 0px;
				background: linear-gradient(to top, rgba(#000,0.4) 50%, rgba(#000,0) 80%); }
			&.bottom {
				padding-top: 15%;
				&:before {
					background: linear-gradient(to top, rgba(#000,0.4) 30%, rgba(#000,0) 60%); } }
			> * {
				color: white;
				font-size: 18px;
				max-width: $pageContentMaxWidth / 2;
				text-shadow: 1px 1px 5px rgba(#000, 0.5), -1px -1px 5px rgba(#000, 0.5), -1px 1px 5px rgba(#000, 0.5), 1px -1px 5px rgba(#000, 0.5);
				margin-left: auto;
				margin-right: auto;
				z-index: $zIndex + 2;
				position: relative;
				white-space: normal;
				border-color: white; }
			h3 {
				font-size: 42px;
				line-height: 48px;
				margin-left: auto; } } } }
